import React, { useState, useEffect } from "react";
import Hamburger from "./Hamburger";
import bellwoods from "../apis/bellwoods";
import history from "../history";
import { UserProfileEditPage as content } from "../content";
import Loader from "./Loader";
import ErrorPage from "./ErrorPage";

const FormData = require("form-data");

const UserProfileEditPage = ({ match }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [expanded, setExpanded] = useState([!match.params.i || match.params.i === "0" ? true : false, match.params.i === "1" ? true : false]);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [phone, setPhone] = useState("");

  // Get the logged in user
  useEffect(() => {
    bellwoods
      .get(`/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.phone);
      setBio(user.bio);
      setEmail(user.email);
    }
  }, [user]);

  // form submits
  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    bellwoods
      .put(
        `/users/${user.id}`,
        {
          firstName: firstName,
          lastName: lastName,
          bio: bio,
          phone: phone,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        history.push(`/profile/`);
        window.location.reload(true);
      })
      .catch((error) => {
        setLoading(false);
        history.push(`/error`);
        window.location.reload(true);
      });
  };

  const onPasswordReset = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);

    bellwoods
      .post("/auth/forgot-password", formData)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        setError(true);
      });
  };

  // helper functions
  const changeExpanded = (index) => {
    var tmp = [...expanded];
    expanded.map((el, i) => {
      if (i === index) {
        tmp[i] = !el;
      } else {
        tmp[i] = false;
      }
      return null;
    });
    setExpanded(tmp);
  };
  if (user && !loading && !error) {
    return (
      <div className="wf-section">
        <div className="container-4 w-container">
          <div className="w-layout-grid grid-15">
            <div id="w-node-_5af7106d-c94b-d07e-67d6-47cac457797c-18dc9456" className="div-block-28 form">
              <div className="text-block-3 profile">
                {content.welcomeMsg[0]} {firstName}
                {content.welcomeMsg[1]}
              </div>
              <h1 className="heading-3">
                {content.header[0]}
                <br />
                {content.header[1]}
              </h1>
            </div>
            <img src="/images/undraw_Hiring_re_yk5n.svg" loading="lazy" id="w-node-_5af7106d-c94b-d07e-67d6-47cac4577985-18dc9456" alt="" className="image-7" />
          </div>
          <div className="w-form">
            <form action="#" id="email-form" name="email-form" data-name="Email Form" onSubmit={onFormSubmit}>
              <div className="form-sub-title" onClick={() => changeExpanded(0)}>
                <h2 className="form-heading">{content.headings[0]}</h2>
                <Hamburger clicked={expanded[0]} onHamClick={() => changeExpanded(0)} />
              </div>
              <div className={`${expanded[0] ? "form-block-show" : "form-block-hide"}`}>
                <div className={`w-layout-grid grid-14`}>
                  <input
                    type="text"
                    className="text-field w-node-_5af7106d-c94b-d07e-67d6-47cac457798e-18dc9456 w-input"
                    maxLength="256"
                    name="fname"
                    data-name="fname"
                    placeholder="first name"
                    id="fname"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName || ""}
                  />
                  <input
                    type="text"
                    className="text-field w-node-_5af7106d-c94b-d07e-67d6-47cac457798f-18dc9456 w-input"
                    maxLength="256"
                    name="lname"
                    data-name="lname"
                    placeholder="last name"
                    id="lname"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName || ""}
                  />
                  <input
                    type="text"
                    className="text-field w-node-_5af7106d-c94b-d07e-67d6-47cac4577990-18dc9456 w-input"
                    maxLength="256"
                    name="phone"
                    data-name="phone"
                    placeholder="phone"
                    id="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone || ""}
                  />
                  <textarea
                    name="bio"
                    maxLength="5000"
                    id="bio"
                    placeholder="bio"
                    data-name="bio"
                    className="text-field text-input w-node-_5af7106d-c94b-d07e-67d6-47cac4577993-18dc9456 w-input"
                    onChange={(e) => setBio(e.target.value)}
                    value={bio || ""}
                  />
                </div>
                <input type="submit" value={content.profileBtn} data-wait="Please wait..." className="button3 listing-button " />
              </div>
            </form>
          </div>
          <div className="w-form">
            <form action="#" onSubmit={onPasswordReset}>
              <div className="form-sub-title" onClick={() => changeExpanded(1)}>
                <h2 className="form-heading">{content.headings[1]}</h2>
                <Hamburger clicked={expanded[1]} onHamClick={() => changeExpanded(1)} />
              </div>
              <div className={` ${expanded[1] ? "form-block-show" : "form-block-hide"}`}>
                <div className="text-block-3 edit">{success ? content.password.success : content.password.main}</div>
                <div className={success ? "d-none" : "w-layout-grid grid-14"}>
                  <input
                    type="text"
                    className="text-field w-node-fe6a549d-86d0-1750-3af9-b21887c8bad9-18dc9456 w-input"
                    maxLength="256"
                    name="email"
                    data-name="email"
                    placeholder="your email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <input type="submit" value={content.passwordBtn} className={success ? "d-none" : "button3 listing-button"} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else if (error) {
    return <ErrorPage />;
  } else {
    return <Loader />;
  }
};

export default UserProfileEditPage;
