import React, { useState } from "react";
import { FindATenant as content } from "../content";
import ListingMessageForm from "./ListingMessageForm";
const FindATenant = ({ listings }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <section id="cards-section" className="cards-section wf-section">
      <div className="centered-container w-container">
        <h2>{content.header}</h2>
        <p className="paragraph">{content.subHeader}</p>
        <div className="cards-grid-container">
          <div id="w-node-_7b2de6f2-759b-0ff8-82c5-aad8f318902a-65674ccc" className="div-block-31 shadow2">
            <div className="cards-image-mask">
              <img
                src="/images/LogoBlack.png"
                sizes="(max-width: 479px) 82vw, (max-width: 767px) 89vw, (max-width: 991px) 308px, 404px"
                srcSet="/images/LogoBlack-p-500.png 500w, /images/LogoBlack-p-800.png 800w, /images/LogoBlack.png 1080w"
                alt=""
                className="cards-image"
              />
            </div>
            <h3>{content.solaceCard.header}</h3>
            <p className="paragraph-2">{content.solaceCard.subheader}</p>
            <span className="button3 " onClick={() => setExpanded(!expanded)}>
              {content.solaceCard.btn}
            </span>
            <div className="div-block-30 mt-4">
              <div>
                <a href={content.solaceCard.link.url} target="_blank" rel="noreferrer">
                  {content.solaceCard.link.label}
                </a>
              </div>
            </div>
          </div>
          <div id="w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189031-65674ccc" className="div-block-31 shadow2">
            <div className="cards-image-mask">
              <img src="/images/undraw_Opened_re_i38e.svg" alt="" className="cards-image" />
            </div>
            <h3 id="w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189034-65674ccc">{content.diyCard.header}</h3>
            <p id="w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189036-65674ccc" className="paragraph-2">
              {content.diyCard.subheader}
            </p>
            <div className="div-block-30">
              <div className="text-block-9">{content.diyCard.small}</div>
            </div>
          </div>
        </div>
        <div className={expanded ? "" : "d-none"}>
          <ListingMessageForm listings={listings} landlord={true} />
        </div>
      </div>
    </section>
  );
};

export default FindATenant;
