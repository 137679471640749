import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import { front_end_url } from "../variables";
import { ListingMessageForm as content } from "../content";

const ListingMessageForm = ({ listings, emailTo, landlord }) => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    // build the message template string
    let propertyTemplate = [];
    if (listings.length) {
      propertyTemplate = listings.map((el, i) => {
        return `${el.Address} (${front_end_url}/listings/show/${el.id})\n`;
      });
      if (landlord) {
        setMessage(content.landlord.msgPrefix + propertyTemplate.join("") + "\n\n" + content.landlord.msgSuffix + `${front_end_url}/profile/user/${localStorage.getItem("userId")}`);
      } else {
        setMessage(content.tenant.msgPrefix + propertyTemplate.join("") + "\n\n" + content.tenant.msgSuffix + `${front_end_url}/profile/user/${localStorage.getItem("userId")}`);
      }
    }
  }, [listings, landlord]);

  if (message) {
    return <ContactForm message={message} emailTo={emailTo} />;
  } else {
    return null;
  }
};
export default ListingMessageForm;
