import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Likes from "./Likes";
import ListingEdit from "./ListingEdit";
import ListingDelete from "./ListingDelete";
import Views from "./Views";

const ListingsListItem = ({ data }) => {
  const [imgHovered, setImgHovered] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [owner, setOwner] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (data.Owner === localStorage.getItem("userId")) {
      setOwner(true);
    }
  }, [data.Owner]);

  const prevImage = () => {
    if (imgIndex === 0) {
      setImgIndex(data.Photos.length - 1);
    } else {
      setImgIndex(imgIndex - 1);
    }
  };
  const nextImage = () => {
    if (imgIndex === data.Photos.length - 1) {
      setImgIndex(0);
    } else {
      setImgIndex(imgIndex + 1);
    }
  };

  const onDeleted = () => {
    setDeleted(true);
  };

  if (data) {
    return (
      <div className={!deleted ? "listing-item shadow2" : "d-none"} onMouseEnter={() => setImgHovered(true)} onMouseLeave={() => setImgHovered(false)}>
        <div data-animation="slide" data-duration="500" data-infinite="1" className="slider-2 w-slider">
          <div className="mask-2 w-slider-mask">
            <div className="w-slide">
              <div className="div-block-25">
                <div>
                  <div className={owner ? "list-item-action w-embed " : "d-none"}>
                    <ListingEdit id={data.id} />
                  </div>
                </div>
                <div>
                  <div className={`list-item-action w-embed ${imgHovered ? "list-item-action-hovered" : ""}`}>
                    <Likes listingId={data.id} />
                  </div>
                </div>
                <div>
                  <div className={owner ? "list-item-action w-embed " : "d-none"}>
                    <ListingDelete listing={data} onSuccess={(id) => onDeleted(id)} />
                  </div>
                </div>
              </div>

              <ImageCarousel index={imgIndex} images={data.Photos} />
            </div>
          </div>
          <div className="left-arrow w-slider-arrow-left">
            <div className="icon-2 w-icon-slider-left" onClick={() => prevImage()}></div>
          </div>
          <div className="right-arrow w-slider-arrow-right">
            <div className="icon w-icon-slider-right" onClick={() => nextImage()}></div>
          </div>
          <div className="slide-nav w-slider-nav w-round"></div>
        </div>
        <Link to={`/listings/show/${data.id}`}>
          <div>
            <div className="w-layout-grid grid-9">
              <div className="div-block-27">
                <div className="text-block-5 dollarsign">$ </div>
                <div className="text-block-5">{data.Price}</div>
              </div>
              <div id="w-node-_72db3371-08ed-3ede-8c10-6c2962569413-885d22ed" className="div-block-26">
                <Likes listingId={data.id} count noClick />
              </div>
              <div id="w-node-e31f7ef7-8604-6919-2f56-ea9bc9f774bc-885d22ed" className="text-block-6 truncate">
                {data.Address}
              </div>
              <div id="w-node-_31edd9f9-9096-4fbd-cc1f-e45a670e871c-885d22ed" className="div-block-26">
                <Views listingId={data.id} />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  } else {
    return null;
  }
};

export default ListingsListItem;

const ImageCarousel = ({ index, images }) => {
  return <img src={images && images.length ? images[index].url : "/images/noImage.jpeg"} loading="lazy" alt="" className="image-3" />;
};
