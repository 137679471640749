import axios from "axios";
import {back_end_url} from "../variables"

const URL = back_end_url;

export default axios.create({
  baseURL: URL, //backend
});

export var bellwoods = URL;
