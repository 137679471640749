import React, { useState } from "react";
import bellwoods from "../apis/bellwoods";
import { PasswordForgot as content } from "../content";

const FormData = require("form-data");

const PasswordForgot = ({ redirect }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);

    bellwoods
      .post("/auth/forgot-password", formData)

      .then((response) => {
        setError(false);
        setSuccess(true);
      })
      .catch((error) => {
        setError(true);
      });
  };
  return (
    <div className="wf-section pw-page">
      <div className="container-4 w-container">
        <div className="w-form">
          <form action="#" id="email-form" name="email-form" data-name="Email Form" onSubmit={onSubmit}>
            <div className="form-sub-title">
              <h2 className="form-heading">{content.heading}</h2>
            </div>
            <h5 className={`${success ? "" : "d-none"}`}>{content.success}</h5>
            <h5 className={`${error ? "" : "d-none"}`}>{content.fail}</h5>
            <div className="form-block-show">
              <div className={"w-layout-grid grid-14"}>
                <input
                  type="text"
                  className="text-field w-node-fe6a549d-86d0-1750-3af9-b21887c8bad9-18dc9456 w-input"
                  maxLength="256"
                  placeholder="your email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <input type="submit" value={content.passwordBtn} data-wait="Please wait..." className={success ? "d-none" : "button3 listing-button"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordForgot;
