import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import ListingForm from "./ListingForm";
import Loader from "./Loader";
import history from "../history";

const ListingEditPage = ({ match }) => {
  const [listing, setListing] = useState(null);
  const [error, setError] = useState(false);

  //get one post on component load
  useEffect(() => {
    bellwoods
      .get(`/listings/${match.params.id}`)
      .then((response) => {
        setListing(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [match.params.id]);

  // couldnt find listing
  useEffect(() => {
    if (error) {
      history.push("/error");
      window.location.reload(true);
    }
  }, [error]);

  if (!listing) {
    return <Loader />;
  } else {
    return <ListingForm listing={listing} />;
  }
};

export default ListingEditPage;
