import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import Loader from "./Loader";
import history from "../history";
import UserProfileTenant from "./UserProfileTenant";
import UserProfileLandlord from "./UserProfileLandlord";

const UserProfilePage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  // Get the logged in user
  useEffect(() => {
    bellwoods
      .get(`/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  // Likely not logged in
  if (error) {
    history.push("/auth/login");
    window.location.reload(true);
  }

  if (user && user.AppRole === "buyer") {
    return <UserProfileTenant user={user} />;
  } else if (user && user.AppRole === "seller") {
    return <UserProfileLandlord user={user} />;
  } else {
    return <Loader />;
  }
};

export default UserProfilePage;
