import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import bellwoods from "../apis/bellwoods";
import { PasswordReset as content } from "../content";
// import Hamburger from "./Hamburger";

const FormData = require("form-data");

const PasswordReset = ({ props }) => {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("password", password);
    formData.append("passwordConfirmation", passwordConfirmation);
    formData.append("code", code);
    bellwoods
      .post("/auth/reset-password", formData)

      .then((response) => {
        setError(false);
        setSuccess(true);
      })
      .catch((error) => {
        setError(true);
      });
  };

  return (
    <div className="wf-section pw-page">
      <div className="container-4 w-container">
        <div className="w-form">
          <form action="#" id="email-form" name="email-form" data-name="Email Form" onSubmit={onSubmit}>
            <div className="form-sub-title">
              <h2 className="form-heading">{content.heading}</h2>
            </div>
            <h5 className={`${success ? "" : "d-none"}`}>{content.success}</h5>
            <h5 className={`${error ? "" : "d-none"}`}>{content.fail}</h5>
            <div className="form-block-show">
              <div className={"w-layout-grid grid-14"}>
                <input
                  type="text"
                  className="text-field w-node-fe6a549d-86d0-1750-3af9-b21887c8bad9-18dc9456 w-input"
                  maxLength="256"
                  placeholder="password"
                  id="email"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <input
                  type="text"
                  className="text-field w-node-fe6a549d-86d0-1750-3af9-b21887c8bad9-18dc9456 w-input"
                  maxLength="256"
                  id="email"
                  placeholder="confirm password"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  value={passwordConfirmation}
                />
              </div>
              <input type="submit" value={content.passwordBtn} data-wait="Please wait..." className={success ? "d-none" : "button3 listing-button"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
