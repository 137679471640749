import React from "react";

const CheckSlider = ({ value, name, onChange, i }) => {
  return (
    <div className="slider-checkbox">
      <input
        type="checkbox"
        id={name}
        onChange={(e) => {
          onChange(e.target.checked, i);
        }}
        checked={value}
      />

      <label className="label utility listing-text" htmlFor={name}>
        {name}
      </label>
    </div>
  );
};

export default CheckSlider;
