import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserCurrent from "./UserCurrent";
import { Authenticate as content } from "../content";
import history from "../history";

const Authenticate = () => {
  const [jwt, setjwt] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      setjwt(localStorage.getItem("jwt"));
    }
  }, []);

  if (jwt) {
    return (
      <>
        <Link to="/profile">
          <div className="navbar-user-text">
            {content.welcomeMsg} <UserCurrent />!
          </div>
        </Link>
        <div
          className="navbar-user-text"
          onClick={() => {
            localStorage.clear();
            history.push(`/`);
            window.location.reload(true);
          }}
        >
          {content.logout}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Link to="/auth/login">
          <div className="navbar-user-text">{content.login}</div>
        </Link>
        <Link to="/auth/register">
          <div className="navbar-user-text">{content.signup}</div>
        </Link>
      </>
    );
  }
};

export default Authenticate;
