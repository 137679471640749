import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import bellwoods from "../apis/bellwoods";
import history from "../history";
import { AuthPage as content } from "../content";

const UserLoginPage = ({ redirect }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    localStorage.clear();
    bellwoods
      .post("/auth/local", { identifier: email, password: password })
      .then((response) => {
        localStorage.setItem("jwt", response.data.jwt);
        localStorage.setItem("firstName", response.data.user.firstName);
        localStorage.setItem("lastName", response.data.user.lastName);
        localStorage.setItem("email", response.data.user.email);
        localStorage.setItem("userId", response.data.user.id);
        localStorage.setItem("appRole", response.data.user.AppRole);
        setError(false);

        history.push(redirect || "/profile");
        window.location.reload(true);
      })
      .catch((error) => {
        setError(true);
      });
  };
  return (
    <div className="auth-form">
      <div className="auth-form-inner">
        <form action="#" className="ui form">
          <h1>{content.login.header}</h1>
          <h5 className={` ${!error ? "d-none" : "auth-msg"}`}>{content.login.error}</h5>
          <h5 className={` ${error ? "d-none" : ""}`}>{content.login.subheader}</h5>
          <div className="field">
            <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="field">
            <input type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          </div>

          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} className="mt-2 mb-3">
            <button className="second-button submit w-100" type="submit" onClick={onSubmit}>
              {content.login.btn}
            </button>
          </Col>

          <div className="second-button w-100">
            <Link className="" to="/password-forgot">
              {content.login.pwBtn}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLoginPage;
