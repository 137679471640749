import React, { useState, useEffect } from "react";
import UserLoginPage from "./UserLoginPage";
import UserRegisterPage from "./UserRegisterPage";

const AuthPage = ({ match }) => {
  const [pageState, setPageState] = useState("register");

  useEffect(() => {
    setPageState(match.params.state);
  }, [match.params.state]);

  return (
    <div className="auth">
      <div className={` shadow auth-card ${pageState === "register" ? "auth-front" : "auth-back"}`} onClick={() => setPageState("register")}>
        <UserRegisterPage />
      </div>

      <div className={`shadow auth-card ${pageState === "login" ? "auth-front" : "auth-back"}`} onClick={() => setPageState("login")}>
        <UserLoginPage />
      </div>
    </div>
  );
};

export default AuthPage;
