import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";

const Views = ({ listingId, listing }) => {
  const [views, setViews] = useState(null);

  useEffect(() => {
    //count the views
    bellwoods.get(`/views/count/?listing.id=${listingId}`).then((response) => {
      setViews(response.data);
    });
  }, [listingId]);

  if (listing) {
    return (
      <div className="div-block-14">
        <div className="listing-icon w-embed">
          <i className="far fa-eye"></i>
        </div>
        <div className="listing-text">{views}</div>
      </div>
    );
  }
  return (
    <>
      <div className="text-block-7">{views}</div>
      <div className="html-embed-2 w-embed">
        <i className="far fa-eye"></i>
      </div>
    </>
  );
};

export default Views;
