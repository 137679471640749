import React, { useState, useEffect } from "react";
import RangeSlider from "./RangeSlider";
import CheckList from "./CheckList";
import CheckSliderMiddle from "./CheckSliderMiddle";

const ListingFilters = ({ content, constants, values, setValues, reset }) => {
  const [expanded, setExpanded] = useState(false);
  const [price, setPrice] = useState([constants.price[0], constants.price[1]]);
  const [beds, setBeds] = useState([constants.beds[0], constants.beds[1]]);
  const [baths, setBaths] = useState([constants.baths[0], constants.baths[1]]);
  const [size, setSize] = useState([constants.size[0], constants.size[1]]);
  const [parkingSpots, setParkingSpots] = useState([constants.parkingSpots[0], constants.parkingSpots[1]]);
  const [leaseLength, setLeaseLength] = useState([constants.leaseLength[0], constants.leaseLength[1]]);
  const [furnished, setFurnished] = useState(null);
  const [pets, setPets] = useState(null);
  const [smoking, setSmoking] = useState(null);
  const [propertyType, setPropertyType] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [appliances, setAppliances] = useState([]);

  useEffect(() => {
    let params = {
      price: price,
      beds: beds,
      baths: baths,
      size: size,
      parkingSpots: parkingSpots,
      leaseLength: leaseLength,
    };
    if (furnished !== null) params.furnished = furnished;
    if (pets !== null) params.pets = pets;
    if (smoking !== null) params.smoking = smoking;

    if (propertyType !== []) params.propertyType = propertyType;
    if (amenities !== []) params.amenities = amenities;
    if (utilities !== []) params.utilities = utilities;
    if (appliances !== []) params.appliances = appliances;

    setValues(params);
  }, [price, beds, baths, size, parkingSpots, leaseLength, furnished, pets, smoking, propertyType, amenities, utilities, appliances]);

  return (
    <>
      <div className="shadow wf-section listing-filter">
        <div className="w-layout-grid grid-12">
          <div id="w-node-_6c9242c6-f86b-af38-7f55-2df3a7eb8834-8cdfb1a7" className="filter-actions">
            <span className="button3 grey" onClick={reset}>
              {content.reset}
            </span>
            <span className="button3 " onClick={() => setExpanded(!expanded)}>
              {content.more}
            </span>
          </div>
          <RangeSlider content={content.price} constants={constants.price} value={values.price} valueChange={(value) => setPrice(value)} />
          <RangeSlider content={content.beds} constants={constants.beds} value={values.beds} valueChange={(value) => setBeds(value)} />
          <RangeSlider content={content.baths} constants={constants.baths} value={values.baths} valueChange={(value) => setBaths(value)} />
          <RangeSlider content={content.size} constants={constants.size} value={values.size} valueChange={(value) => setSize(value)} />
        </div>
        <div className={`w-layout-grid grid-12 ${expanded ? "filter-expanded" : "d-none"}`}>
          <RangeSlider content={content.leaseLength} constants={constants.leaseLength} value={values.leaseLength} valueChange={(value) => setLeaseLength(value)} />
          <RangeSlider content={content.parkingSpots} constants={constants.parkingSpots} value={values.parkingSpots} valueChange={(value) => setParkingSpots(value)} />
          <div id="w-node-_757d57fe-dfb7-e160-41ad-4c1021938e7a-8cdfb1a7" className="yes-no-maybe">
            <CheckSliderMiddle label={content.pets} value={values.pets} valueChange={(value) => setPets(value)} />
            <CheckSliderMiddle label={content.furnished} value={values.furnished} valueChange={(value) => setFurnished(value)} />
            <CheckSliderMiddle label={content.smoking} value={values.smoking} valueChange={(value) => setSmoking(value)} />
          </div>
          <div id="w-node-_85b389fa-ac5e-e991-666a-add8205fa876-8cdfb1a7" className="checklist">
            <CheckList content={content.propertyType} constants={constants.propertyType} valueChange={setPropertyType} state={propertyType} />
          </div>
          <div id="w-node-e8fc0894-9c1a-960a-9343-c1b61f60ff80-8cdfb1a7" className="checklist">
            <CheckList content={content.amenities} constants={constants.amenities} valueChange={setAmenities} state={amenities} />
          </div>
          <div id="w-node-_26f91d16-3848-94fd-32ca-d5608763e73a-8cdfb1a7" className="checklist">
            <CheckList content={content.utilities} constants={constants.utilities} valueChange={setUtilities} state={utilities} />
          </div>
          <div id="w-node-cbbdfc56-c2e0-83a9-2880-400ec99436ed-8cdfb1a7" className="checklist">
            <CheckList content={content.appliances} constants={constants.appliances} valueChange={setAppliances} state={appliances} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingFilters;
