import React, { useState } from "react";
import bellwoods from "../apis/bellwoods";
import { PhotoDelete as content } from "../content";

const PhotoDelete = ({ id, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = (e) => {
    setLoading(true);
    e.preventDefault();
    bellwoods
      .delete(`/upload/files/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        handleDelete(id);
      })
      .catch((error) => {
        handleDelete(id);
      });
  };

  return (
    <span className="button pt-2 pb-2" onClick={(e) => handleClick(e)}>
      {loading ? content.loading : content.delete}
    </span>
  );
};

export default PhotoDelete;
