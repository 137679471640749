import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import ProfileBio from "./ProfileBio";
import ProfileListings from "./ProfileListings";
import FindATenant from "./FindATenant";

const UserProfileLandlord = ({ user }) => {
  const [listings, setListings] = useState([]);

  //get all listings owned by this user on component load
  useEffect(() => {
    bellwoods.get(`/listings?Owner=${localStorage.getItem("userId")}`).then((response) => {
      setListings(response.data);
    });
  }, []);

  return (
    <>
      <ProfileBio user={user} />
      <ProfileListings user={user} listings={listings} />
      <FindATenant listings={listings} />
    </>
  );
};

export default UserProfileLandlord;
