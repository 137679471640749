import React from "react";

const Pagination = ({ length, page, setPage, step }) => {
  const max = Math.ceil(length / step);
  const min = 1;
  const offset = 2;

  const changePage = (i) => {
    if (i > 0 && page !== max) {
      setPage(page + 1);
    } else if (i < 0 && page !== min) {
      setPage(page - 1);
    }
  };
  const renderBtns = () => {
    let btns = [];
    for (let i = 1; i <= max; i++) {
      if (Math.abs(i - page) < offset) {
        btns.push(
          <button key={i} className={i === page ? "selected" : ""} onClick={() => setPage(i)}>
            {i}
          </button>
        );
      }
    }
    return btns;
  };

  return (
    <div className="pagination">
      <div>
        <button onClick={() => changePage(-1)}>{"<<"}</button>
        {renderBtns()}
        <button onClick={() => changePage(1)}>{">>"}</button>
      </div>
    </div>
  );
};

export default Pagination;
