import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import ListingsList from "./ListingsList";
import ListingsMap from "./ListingsMap";
import ListingFilters from "./ListingFilters";
import qs from "qs";

import { ListingsPage as content } from "../content";

const constants = {
  // min, max, step
  pageLength: 20,
  price: [0, 6000, 10],
  beds: [0, 10, 1],
  baths: [0, 6, 1],
  size: [0, 6000, 50],
  leaseLength: [0, 48, 6],
  parkingSpots: [0, 10, 1],
  amenities: ["gym", "wheelchair access", "balcony", "fireplace", "pool"],
  utilities: ["heat", "internet", "electricity & hydro", "satellite", "cable", "water"],
  appliances: ["airconditioning", "dishwasher", "laundry ensuite", "laundry facilities"],
  propertyType: ["appartment", "condominium", "single-family home", "townhouse", "co-op", "multi-family home", "other"],
};

// must match strapi database types
const variables = {
  amenities: ["Gym", "WheelchairAccess", "Balcony", "Fireplace", "Pool"],
  utilities: ["HeatIncluded", "InternetIncluded", "ElectricityHydroIncluded", "SatelliteIncluded", "CableIncluded", "WaterIncluded"],
  appliances: ["AirConditioning", "Dishwasher", "LaundryEnsuite", "LaundryFacilities"],
  propertyType: ["appartment", "condominium", "single-family home", "townhouse", "co-op", "multi-family home", "other"],
};

const ListingsPage = () => {
  // pagingation
  const [page, setPage] = useState(1);
  const [listingsTotal, setListingsTotal] = useState(10);
  // other
  const [showMap, setShowMap] = useState(true);
  const [markerHovered, setMarkerHovered] = useState(null);
  const [markerClicked, setMarkerClicked] = useState(0);
  const [listings, setListings] = useState([]);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    // basics
    price: [constants.price[0], constants.price[1]],
    beds: [constants.beds[0], constants.beds[1]],
    baths: [constants.baths[0], constants.baths[1]],
    size: [constants.size[0], constants.size[1]],
    // Advanced
    parkingSpots: [constants.parkingSpots[0], constants.parkingSpots[1]],
    leaseLength: [constants.leaseLength[0], constants.leaseLength[1]],
    furnished: null,
    pets: null,
    smoking: null,
    propertyType: [],
    amenities: [],
    utilities: [],
    appliances: [],
  });

  const [delayHandler, setDelayHandler] = useState(null);

  useEffect(() => {
    clearTimeout(delayHandler);
    setDelayHandler(
      setTimeout(() => {
        getListings();
      }, 1000)
    );
  }, [filter, page]);

  //get all posts on component load
  const getListings = () => {
    const queryFilters = {
      Price_gte: filter.price[0],
      Price_lte: filter.price[1],
      Beds_gte: filter.beds[0],
      Beds_lte: filter.beds[1],
      Baths_gte: filter.baths[0],
      Baths_lte: filter.baths[1],
      Size_gte: filter.size[0],
      Size_lte: filter.size[1],
    };
    if (filter.parkingSpots[0]) queryFilters.ParkingSpots_gte = filter.parkingSpots[0];
    if (filter.parkingSpots[1]) queryFilters.ParkingSpots_lte = filter.parkingSpots[1];
    if (filter.leaseLength[0]) queryFilters.LeaseLength_gte = filter.leaseLength[0];
    if (filter.leaseLength[1]) queryFilters.LeaseLength_lte = filter.leaseLength[1];

    queryFilters.Furnished = filter.furnished;
    queryFilters.Smoking = filter.smoking;
    queryFilters.Pets = filter.pets;

    // Build amenities filter
    constants.amenities.forEach((el, i) => {
      if (filter.amenities.includes(el)) queryFilters[variables.amenities[i]] = true;
    });
    // Build utilities filter
    constants.utilities.forEach((el, i) => {
      if (filter.utilities.includes(el)) queryFilters[variables.utilities[i]] = true;
    });
    // Build appliances filter
    constants.appliances.forEach((el, i) => {
      if (filter.appliances.includes(el)) queryFilters[variables.appliances[i]] = true;
    });

    // Build property type filter using strapi _or syntax
    let or = [];
    constants.propertyType.forEach((el, i) => {
      if (filter.propertyType.includes(el)) or.push([{ PropertyType: variables.propertyType[i] }]);
    });
    if (or !== []) queryFilters._or = or;

    // consolidate the filters
    const query = qs.stringify({
      _where: [queryFilters],
    });

    // count listings
    bellwoods
      .get(`/listings/count?${query}`)
      // .get(`/listings`)
      .then((response) => {
        setListingsTotal(response.data);

        let start = constants.pageLength * (page - 1);

        // paginated results
        bellwoods
          .get(`/listings?_start=${start}&_limit=${constants.pageLength}&${query}`)
          // .get(`/listings`)
          .then((response) => {
            setListings(response.data);
          })
          .catch((error) => {
            setError(error);
          });
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onFilter = (values) => {
    setFilter({ ...values, values });
  };

  // Reset filters
  const resetFilters = () => {
    window.location.reload();
  };

  // MAPS
  //once the listings have loaded make an array of map pins
  useEffect(() => {
    if (listings.length) {
      setLocations(
        listings.map((el, id) => {
          return { lat: el.Latitude, lng: el.Longitude };
        })
      );
    } else {
      setLocations([]);
    }
  }, [listings]);

  // Helper functions for hovering and clicking map markers (not groups)
  const hoverMarker = (id) => {
    setMarkerHovered(id);
  };
  const clickMarker = (id) => {
    setMarkerClicked(id);
    // redirect to that listing
    window.open(`/listings/show/${listings[id].id}`, "_blank").focus();
  };

  return (
    <>
      <ListingFilters content={content.filters} constants={constants} values={filter} setValues={onFilter} reset={resetFilters} />
      <div className="listings-page">
        <span onClick={() => setShowMap(!showMap)} id="map-btn" className="button3">
          {showMap ? content.hide : content.show}
        </span>
        <div className={showMap ? "map-show" : "map-hide"}>
          <ListingsMap locations={locations} hoverMarker={hoverMarker} clickMarker={clickMarker} />
        </div>
        <div className={`maps-tooltip ${markerHovered === null ? "map-tooltip-hide" : "map-tooltip-show"}`}>{listings[markerHovered] ? listings[markerHovered].Address : null}</div>
        <ListingsList listings={listings} error={error} hovered={markerHovered} clicked={markerClicked} page={page} setPage={setPage} listingsTotal={listingsTotal} pageLength={constants.pageLength} />
      </div>
    </>
  );
};

export default ListingsPage;
