import React, { useState, useEffect, useCallback } from "react";
import geocode from "../apis/geocode";
import { MAPS_API_KEY } from "../variables";
import { GetCoords as content } from "../content";

const GetCoords = ({ address, setLatitude, setLongitude, addressEntered, initialAddress, setAddress, validated, setValidated }) => {
  const [addressURL, setAddressURL] = useState("");
  const [state, setState] = useState("button");
  const [addresses, setAddresses] = useState([]);

  const getCoords = useCallback(() => {
    setState("loading");
    geocode
      .get(`/json?address=${addressURL}&key=${MAPS_API_KEY}`)
      .then((response) => {
        setAddresses(response.data.results);
        setState("success");
      })
      .catch(() => {
        setState("error");
      });
  }, [addressURL]);

  useEffect(() => {
    setState("button");
    if (address !== initialAddress) {
      setLatitude(0);
      setLongitude(0);
    }

    setAddressURL(encodeURIComponent(address));
  }, [address]);

  useEffect(() => {
    if (addressEntered) getCoords();
  }, [addressEntered, getCoords]);

  const renderList = () => {
    if (state === "success") {
      return (
        <div id="w-node-f4538895-c086-083c-ffe4-4353f2e5f132-be368e20">
          <h5 className="heading-6">{content.listHeader}</h5>

          <ul className="w-list-unstyled">
            {addresses.map((el, i) => {
              return (
                <li
                  key={i}
                  className="list-item shadow zoom"
                  onClick={() => {
                    setLatitude(el.geometry.location.lat);
                    setLongitude(el.geometry.location.lng);
                    setAddress(el.address_components[0].long_name + " " + el.address_components[1].long_name);
                    setState("button");
                  }}
                >
                  {el.formatted_address}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (state === "error") {
      return (
        <div id="w-node-f4538895-c086-083c-ffe4-4353f2e5f132-be368e20">
          <h5 className="heading-6">{content.error}</h5>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div id="w-node-_04242738-04bc-69d4-6b08-3be51e0ad822-be368e20">
        <span
          onClick={() => {
            setValidated();
            getCoords();
          }}
          className={`button-2 shadow2 w-button ${!validated ? "val-error" : ""}`}
        >
          {state === "loading" ? content.loading : content.button}
        </span>
      </div>
      {renderList()}
    </>
  );
};

export default GetCoords;
