import React, { useState, useEffect } from "react";
import CheckSlider from "./CheckSlider";

const CheckList = ({ content, constants, valueChange, state }) => {
  const [list, setList] = useState(state);

  const handleChange = (value, i) => {
    var tmp = [...list];
    tmp[i] = value;
    setList(tmp);
  };

  useEffect(() => {
    let tmp = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i]) {
        tmp.push(constants[i]);
      }
    }
    valueChange(tmp);
  }, [list, constants, valueChange]);

  return (
    <>
      <div className="div-block-9 app">
        <div className="listing-icon w-embed">
          <i className={content.icon}></i>
        </div>
        <div className="listing-text">{content.label}</div>
      </div>
      {constants.map((el, i) => {
        return <CheckSlider key={i} value={list[i]} name={el} onChange={handleChange} i={i} />;
      })}
    </>
  );
};

export default CheckList;
