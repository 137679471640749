const register_redirect = "/profile";
export { register_redirect };

// const front_end_url = "http://localhost:3000";
const front_end_url = "http://www.bellwoodsweb2.website";
export { front_end_url };

const back_end_url = "https://fierce-everglades-26770.herokuapp.com";
// const back_end_url = "http://localhost:1337";
export { back_end_url };

const admin_email = "davisl6@mcmaster.ca";
export { admin_email };

const MAPS_API_KEY = "AIzaSyCFKyTE8GgHJRULUiODnnc2a0FjXGWXoqw";
export { MAPS_API_KEY };

const GEOCODE_URL = "https://maps.googleapis.com/maps/api/geocode";
export { GEOCODE_URL };
