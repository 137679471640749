import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import history from "../history";

const Likes = ({ listingId, count, listing, noClick }) => {
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);

  //determine initial state of like
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      bellwoods.get(`/likes?listing=${listingId}&users_permissions_user=${localStorage.getItem("userId")}`).then((response) => {
        if (response.data.length !== 0) {
          setLiked(true);
        }
      });
    }
  }, [listingId]);

  //count the likes every time likes changes
  useEffect(() => {
    if (count) {
      bellwoods.get(`/likes/count/?listing.id=${listingId}`).then((response) => {
        setLikes(response.data);
      });
    }
  }, [likes, listingId, count]);

  //like button was clicked
  const onClick = (e) => {
    e.preventDefault();
    if (!noClick) {
      //check if users logged in
      if (localStorage.getItem("userId")) {
        //decide what to do based on state
        if (liked) {
          unlike();
        } else {
          like();
        }
        setLiked(!liked);
      } else {
        //not logged in? go do it
        history.push("/auth/login");
        window.location.reload(true);
      }
    }
  };
  const unlike = () => {
    //find a like to delete
    bellwoods.get(`/likes?listing=${listingId}&users_permissions_user=${localStorage.getItem("userId")}`).then((response) => {
      bellwoods
        .delete(`/likes/${response.data[0].id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          setLikes(likes - 1);
        });
    });
  };

  const like = () => {
    //create a like
    bellwoods
      .post(
        `/likes/`,
        {
          users_permissions_user: [localStorage.getItem("userId")],
          listing: [listingId],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((response) => {
        setLikes(likes + 1);
      });
  };

  if (listing) {
    return (
      <div className="div-block-13">
        <div className="listing-icon w-embed">
          {/* <i className="far fa-heart"></i> */}
          {liked ? <i className="fas fa-heart" onClick={onClick} /> : <i className="far fa-heart zoom" onClick={onClick} />}
        </div>
        <div className="listing-text">{likes}</div>
      </div>
    );
  }
  return (
    <>
      <div className={`text-block-7 ${count ? "" : "d-none"}`}>{likes}</div>
      <div className="html-embed-2 w-embed">{liked ? <i className="fas fa-heart" onClick={onClick} /> : <i className="far fa-heart zoom" onClick={onClick} />}</div>
    </>
  );
};

export default Likes;
