import React, { useEffect } from "react";
import { back_end_url } from "../variables";
import history from "../history";

const RedirectEmailConfirm = ({ match }) => {
  useEffect(() => {
    if (match.params.confirmation) window.location.replace(back_end_url + `/auth/email-confirmation?confirmation=${match.params.confirmation}`);

    history.push("/auth/login");
    window.location.reload(true);
  }, [match.params.confirmation]);
  return <div>hi</div>;
};

export default RedirectEmailConfirm;
