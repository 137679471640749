import React, { useState, useEffect } from "react";

const JsonInput = ({ defaultNum, onChange, name, value, secondaryFormat }) => {
  const [values, setValues] = useState(value || []);
  const [inputs, setInputs] = useState(new Array(defaultNum).fill(1));

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return (
    <div className="json-input">
      <div className="w-layout-grid grid-17">
        {inputs.map((el, i) => {
          return (
            <input
              key={i}
              type="text"
              className={
                i === inputs.length - 1 && secondaryFormat
                  ? "text-field w-node-b7353bf5-c7de-bcdd-6b82-05e5d602c4d6-be368e20 w-input"
                  : "text-field w-node-_4c535d94-59e7-d2ec-365d-b7ae6caed52a-be368e20 w-input"
              }
              maxLength="256"
              id={i === inputs.length - 1 && secondaryFormat ? "outdoor-spaces-3" : "outdoor-spaces"}
              value={values[i]}
              onChange={(e) => {
                var tmp = [...values];
                tmp[i] = e.target.value;
                setValues(tmp);
              }}
              placeholder={name}
            />
          );
        })}
        <div id="w-node-b76bfb92-4b2a-e0d9-0dcb-b79896462491-be368e20" className="div-block-35">
          <span
            className="button-3 w-button json-btn"
            onClick={(e) => {
              e.preventDefault();
              var tmp = [...inputs];
              tmp.pop();
              setInputs(tmp);
              var tmp2 = [...values];
              tmp2.pop();
              setValues(tmp2);
            }}
          >
            -
          </span>
          <span
            className="button-3 w-button json-btn"
            onClick={(e) => {
              e.preventDefault();
              var tmp = [...inputs, 1];
              setInputs(tmp);
              var tmp2 = [...values, ""];
              setValues(tmp2);
            }}
          >
            +
          </span>
        </div>
      </div>
    </div>
  );
};

export default JsonInput;
