import React from "react";

const Hamburger = ({ clicked, onHamClick }) => {
  return (
    <div className="hamburger">
      <div onClick={() => onHamClick()} className={`nav-menu-arrow ${clicked ? "nav-menu-arrow-clicked" : ""}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Hamburger;
