import React from "react";
import { ErrorPage as content } from "../content";
const ErrorPage = ({ header, subheader }) => {
  return (
    <div className="errorPage">
      <h1>{header || content.header}</h1>
      <h4>{subheader || content.subheader}</h4>
      <img src="/images/undraw_Notify_re_65on.svg" alt="error" />
    </div>
  );
};

export default ErrorPage;
