import React from "react";
import { Link } from "react-router-dom";
import { Footer as content } from "../content";

const Footer = () => {
  return (
    <div className="footer wf-section">
      <div className="div-block-4">
        <div className="w-layout-grid grid-6">
          <div id="w-node-_227822fa-a0f5-aada-eaa8-b34c2596cbe4-f1ab5453" className="div-block-8">
            {content.links.map((el, i) => {
              return (
                <Link key={i} className="nav-link-footer" to={el.url}>
                  {el.label}
                </Link>
              );
            })}
          </div>
          <div id="w-node-f39fd9af-af72-a3de-7f46-55e82dd6abfd-f1ab5453" className="div-block-21">
            <Link to="/listings/show">
              {/* <img
                src="/images/Logo1.png"
                loading="lazy"
                sizes="(max-width: 767px) 125px, 150px"
                srcSet="/images/Logo1-p-500.png 500w, /images/Logo1-p-800.png 800w, /images/Logo1.png 1080w"
                alt="hamilton for rent logo"
                className="foorer-logo-main"
              /> */}
            </Link>
          </div>
          <div id="w-node-_832aec8c-5a4e-158e-683f-58d13a3dd5b5-f1ab5453" className="div-block-6">
            <a href="https://www.solacesolutions.ca" target="_blank" rel="noreferrer" className="w-inline-block">
              <img
                src="/images/LogoSolace.png"
                loading="lazy"
                sizes="(max-width: 479px) 40vw, (max-width: 767px) 125px, 175px"
                srcSet="/images/LogoSolace-p-500.png 500w, /images/LogoSolace-p-800.png 800w, /images/LogoSolace.png 972w"
                alt="solace solutions logo"
                className="footer-logo"
              />
            </a>
            <a href="https://www.bellwoodsweb.com" target="_blank" rel="noreferrer" className="w-inline-block">
              <img
                src="/images/LogoBellwoods.png"
                loading="lazy"
                sizes="(max-width: 479px) 40vw, (max-width: 767px) 125px, 175px"
                srcSet="/images/LogoBellwoods-p-500.png 500w, /images/LogoBellwoods-p-800.png 800w, /images/LogoBellwoods-p-1080.png 1080w, /images/LogoBellwoods-p-1600.png 1600w, /images/LogoBellwoods-p-2000.png 2000w, /images/LogoBellwoods-p-2600.png 2600w, /images/LogoBellwoods-p-3200.png 3200w, /images/LogoBellwoods.png 8116w"
                alt="bellwoods web development logo"
                className="footer-logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="div-block-5"></div>
    </div>
  );
};

export default Footer;
