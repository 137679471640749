import React, { useState } from "react";
import bellwoods from "../apis/bellwoods";

const ListingDelete = ({ listing, onSuccess }) => {
  const handleDelete = (e) => {
    e.preventDefault();
    bellwoods
      .delete(`/listings/${listing.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        onSuccess(listing.id);
        console.log(response);
      });
  };

  return <i onClick={(e) => handleDelete(e)} className="far fa-trash-alt zoom"></i>;
};

export default ListingDelete;
