import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import qs from "qs";
import ProfileBio from "./ProfileBio";
import ProfileListings from "./ProfileListings";
import FindAHome from "./FindAHome";

const UserProfileTenant = ({ user }) => {
  const [likes, setLikes] = useState(null);
  const [listings, setListings] = useState([]);

  useEffect(() => {
    // get all the likes this user has
    bellwoods.get(`/likes?users_permissions_user=${localStorage.getItem("userId")}`).then((response) => {
      setLikes(response.data);
    });
  }, []);

  //get all listings liked by this user
  useEffect(() => {
    let tmp = [];
    if (likes && likes.length) {
      likes.map((el) => {
        if (el.listing) tmp.push([{ id: el.listing.id }]);
        return null;
      });

      const query = qs.stringify({
        _where: {
          _or: tmp,
        },
      });

      // get all the liked listings this user has
      bellwoods.get(`/listings?${query}`).then((response) => {
        setListings(response.data);
      });
    }
  }, [likes]);

  return (
    <>
      <ProfileBio user={user} />
      <ProfileListings user={user} listings={listings} />
      <FindAHome />
    </>
  );
};

export default UserProfileTenant;
