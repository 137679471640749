import React, { useState } from "react";
import Slider from "react-slick";
import "../css/Carousel3D.css";
import ListingsListItem from "./ListingsListItem";
import useWindowDimensions from "../hooks/WindowSize";

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <i className="fas fa-arrow-circle-right"></i>
    </div>
  );
};
const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <i className="fas fa-arrow-circle-left"></i>
    </div>
  );
};
const Carousel3D = ({ listings }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const { width } = useWindowDimensions();

  const settings = {
    infinite: true,
    lazyload: true,
    speed: 300,
    slidesToShow: width > 670 && listings.length >= 3 ? 3 : 1,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  return (
    <div>
      <Slider {...settings}>
        {listings.map((el, i) => (
          <div key={i} className={`${i === imageIndex ? "slide activeSlide" : "slide"} ${width < 670 ? "small-carousel" : ""}`}>
            <ListingsListItem data={el} like />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel3D;
