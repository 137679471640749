import React, { useState } from "react";
import { Link } from "react-router-dom";
import Authenticate from "./Authenticate";
import Hamburger from "./Hamburger";
import { Navigation as content } from "../content";

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null);

  // Navbar leave hover delay
  const handleMouseEnter = (event) => {
    clearTimeout(delayHandler);
  };

  const handleMouseLeave = () => {
    setDelayHandler(
      setTimeout(() => {
        setOpen(false);
      }, 1000)
    );
  };

  return (
    <div className={`navbar shadow wf-section`} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
      <div className="w-layout-grid navbar-top">
        <div id="w-node-_4818eeae-96ef-f943-8f3b-8f68e641c944-678a698d">
          <div className="html-embed w-embed">
            <Hamburger clicked={open} onHamClick={() => setOpen(!open)} />
          </div>
        </div>
        <div id="w-node-_0d2eed62-1b10-2378-235d-408e6a238a2f-678a698d">
          <Link className="nav-link" to="/listings/show">
            <p className="text-center">This is a sample application. Its a marketplace for real estate listings where tenants and landlords can interact.</p>
            {/* <img
              src="/images/Logo2.png"
              loading="lazy"
              sizes="(max-width: 479px) 108.515625px, 162.78125px"
              srcSet="/images/Logo2-p-500.png 500w, /images/Logo2.png 675w"
              alt=""
              className="logo-main"
            /> */}
          </Link>
        </div>
        <div className="div-block-3">
          <Authenticate />
          <Link className="nav-link" to="/profile">
            <img
              src="/images/UserIcon.png"
              loading="lazy"
              sizes="(max-width: 479px) 50px, 60px"
              srcSet="/images/UserIcon-p-500.png 500w, /images/UserIcon-p-800.png 800w, /images/UserIcon.png 1080w"
              alt=""
              className="profile-icon"
            />
          </Link>
        </div>
      </div>
      <div className={`navbar-drop main-second-nav container ${open ? "menu-open" : "menu-closed"}`}>
        {content.links.map((el, i) => {
          return (
            <Link key={i} className="nav-link" to={el.url}>
              {el.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;
