import axios from "axios";
import { GEOCODE_URL } from "../variables";

const URL = GEOCODE_URL;

export default axios.create({
  baseURL: URL, //backend
});

export var geocode = URL;
