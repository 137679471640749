export const Navigation = {
  links: [
    { label: "home", url: "/" },
    { label: "listings", url: "/listings/show" },
    { label: "contact", url: "/contact" },
  ],
};
export const Footer = {
  links: [
    { label: "home", url: "/" },
    { label: "listings", url: "/listings/show" },
    { label: "contact", url: "/contact" },
    { label: "profile", url: "/profile" },
  ],
};
export const LandingPage = {
  landlordButton: {
    text: "landlords",
    url: "/auth/register",
  },
  tenantButton: {
    text: "tenants",
    url: "/listings/show",
  },
  hamilton: {
    header: ["Call ", "Hamilton", " Home"],
    subheader:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  },
};
export const Authenticate = { welcomeMsg: "Hello", logout: "logout", signup: "sign up", login: "login" };
export const ProfileBio = {
  welcomeMsg: "welcome to hamilton",
  welcomeBtn: {
    seller: "Add a property",
    buyer: " Find a home",
  },
  defaultBio: {
    seller: "Stand out to potential tenants by providing them with some information about yourself.",
    buyer: "Landlords are much more likely to rent to tenants they're comfortable with. Complete your profile to increase your odds of landing that new home!",
  },
  passwordBtn: "change password",
  editBtn: "edit info",
};
export const ProfileListings = {
  listingHeader: {
    buyer: "Liked Listings",
    seller: "Properties",
  },
};
export const FindAHome = {
  header: "What Now?",
  subHeader:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
};
export const FindATenant = {
  header: "Let's Find You A Tenant!",
  subHeader:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
  solaceCard: {
    header: "Solace Soultions",
    subheader: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor.",
    btn: "we'll handle it",
    link: {
      label: "who is solace solutions?",
      url: "https://www.solacesolutions.ca",
    },
  },
  diyCard: {
    header: "Do-It-Yourself",
    subheader: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
    small: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in.",
  },
};
export const ListingPage = {
  priceUnit: "/month",
  beds: "beds",
  baths: "baths",
  parking: "parking",
  size: "size",
  months: "months",
  pets: "pets",
  dishwasher: "dishwasher",
  gym: "gym",
  accessible: "accessible",
  balcony: "balcony",
  smoking: "smoking",
  fireplace: "fireplace",
  pool: "pool",
  laundryFacilities: "laundry facilities",
  laundryEnsuite: "laundry ensuite",
  furnished: "furnished",
  parkingTypeHeader: "parking type",
  utilitiesHeader: "utilities included",
  amenitiesHeader: "other amenities",
  appliancesHeader: "other appliances",
  outdoorHeader: "outdoor spaces",
  internet: "internet",
  electricity: "electricity",
  satellite: "satellite",
  cable: "cable",
  water: "water",
  heat: "heat",

  msgBtn: {
    heading: "speak with the landlord",
    btn: "message us today",
  },
  edit: "edit",
  delete: "delete",
};

export const ContactForm = {
  header: "send a message to",
  placeholder: {
    name: "what's your name?",
    email: "what's your email?",
    msg: "what can we do for you?",
  },

  btn: "send",
  captcha: {
    on: "i'm not a robot",
    off: "i'm a robot",
  },
  success: {
    header: "thank you!",
    subheader: "we'll get back to you as soon as possible",
  },
  error: {
    header: "whoops...",
    subheader: "something went wrong, please email",
  },
};
export const ListingMessageForm = {
  landlord: {
    msgPrefix: "I'm looking for help renting the following properties:\n\n",
    msgSuffix: "Here's a link to my profile:\n",
  },
  tenant: {
    msgPrefix: "I'm interested in renting the following properties:\n\n",
    msgSuffix: "Here's a link to my profile:\n",
  },
};

export const AnyUserProfilePage = {
  header: "Hmmm...",
  subheader: "We can't seem to find this user",
};
export const ErrorPage = {
  header: "error 404",
  subheader: "we can't seem to find what you're looking for",
};
export const ListingsPage = {
  show: "show map",
  hide: "hide map",
  filters: {
    reset: "reset",
    more: "more",
    price: {
      label: "price",
      icon: "fas fa-tags",
    },
    beds: {
      label: "beds",
      icon: "fas fa-bed",
    },
    baths: {
      label: "baths",
      icon: "fas fa-bath",
    },
    size: {
      label: "size",
      icon: "size-icon",
    },
    leaseLength: {
      label: "length",
      icon: "far fa-clock",
    },
    parkingSpots: {
      label: "parking",
      icon: "fas fa-car",
    },
    pets: "pets",
    furnished: "furnished",
    smoking: "smoking",
    propertyType: {
      label: "property type",
      icon: "far fa-building",
    },
    appliances: {
      label: "appliances",
      icon: "far fa-snowflake",
    },
    utilities: {
      label: "utilities",
      icon: "fas fa-fire",
    },
    amenities: {
      label: "amenities",
      icon: "fas fa-dumbbell",
    },
  },
};
export const ListingForm = {
  subheader: {
    edit: "looking for leasing help?",
    new: "looking for leasing help?",
  },
  header: {
    edit: ["solace solutions", "is here to help"],
    new: ["solace solutions", "is here to help"],
  },
  btn: {
    label: "find out more",
    url: "https://www.solacesolutions.ca",
  },
  photoChoose: "Choose Photos",
  submitBtn: "Submit",

  headings: ["basic info", "other", "current photos", "photo upload"],
  address: "address",
  price: "price",
  phone: "phone",
  email: "email",
  leaseLength: "lease length",
  description: "description",
  dateAdded: "date added",
  latitude: "latitude",
  longitude: "longitude",
  beds: "beds",
  baths: "baths",
  size: "size",
  parkingSpots: "parking spots",
  parkingType: {
    label: "parking type",
    options: ["street", "garage", "driveway", "none"],
  },
  propertyType: {
    label: "property type",
    options: ["appartment", "condominium", "single-family home", "townhouse", "co-op", "multi-family home", "other"],
  },
  outdoorSpace: "outdoor spaces",
  pets: "pets",
  gym: "gym",
  wheelchairAccess: "wheelchair access",
  balcony: "balcony",
  smoking: "smoking",
  fireplace: "fireplace",
  pool: "pool",
  furnished: "furnished",
  utilities: "utilities included",
  appliances: "appliances included",
  amenities: "amenities",
  other: "other",
  heatIncluded: "heat",
  internetIncluded: "internet",
  electricityHydroIncluded: "electricity & hydro",
  satelliteIncluded: "satellite",
  cableIncluded: "cable",
  waterIncluded: "water",
  airConditioning: "air conditioning",
  dishwasher: "dishwasher",
  laundryEnsuite: "laundry ensuite",
  laundryFacilities: "laundry facilities",
};

export const UserProfileEditPage = {
  welcomeMsg: ["hi ", "!"],
  header: ["Edit Your", "Profile"],
  headings: ["your info", "change password"],
  password: {
    success: "thanks! please go check your email",
    main: "we'll send you an email",
  },
  profileBtn: "edit profile",
  passwordBtn: "reset password",
};
export const PasswordReset = {
  heading: "reset your password",
  success: "password reset successful",
  fail: "woops! something went wrong... please try again",
};
export const PasswordForgot = {
  heading: "forgot your password?",
  success: "please go check your email!",
  fail: "woops! something went wrong... please try again",
};
export const ContactPage = {
  subheader: "we'd love to hear from you",
  header: "get in touch!",
};

export const GetCoords = {
  button: "search address",
  loading: "searching...",
  error: "address not found",
  listHeader: "Please select the correct address",
};
export const ListingsNoResults = {
  header: "0 Results Found",
  subheader: "Sorry, we couldnt find any listings that match your search results.",
};
export const PhotoDelete = {
  loading: "one sec...",
  delete: "delete",
};
export const AuthPage = {
  login: {
    header: "Login",
    subheader: "welcome to the site!",
    btn: "login",
    error: "woops! something went wrong... please try again",
    pwBtn: "forgot your password?",
  },
  register: {
    header: "Sign Up",
    subheader: "renting made easier!",
    success: "Welcome!",
    btn: "register",
    error: "woops! something went wrong... please try again",
    email: "go check your email to confirm",
    tenant: "tenant",
    landlord: "landlord",
  },
};
