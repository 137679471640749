import React, { useEffect } from "react";

import ListingForm from "./ListingForm";
import history from "../history";
const ListingAddPage = () => {
  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
      window.location.reload(true);
    }
  }, []);
  return (
    <div>
      <ListingForm />
    </div>
  );
};

export default ListingAddPage;
