import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import bellwoods from "../apis/bellwoods";
import { AuthPage as content } from "../content";

const FormData = require("form-data");

const UserRegisterPage = ({ redirect }) => {
  const [appRole, setAppRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [validateError, setValidateError] = useState({
    appRole: false,
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      localStorage.clear();

      const formData = new FormData();
      formData.append("username", email);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("AppRole", appRole);

      bellwoods
        .post("/auth/local/register", formData)
        .then((response) => {
          setError(false);
          setRegistered(true);
        })
        .catch((error) => {
          setError(true);
        });
    }
  };
  const validateForm = () => {
    var validated = true;
    var tmpValError = {};
    if (appRole === "") {
      tmpValError.appRole = true;
      validated = false;
    }
    if (firstName === "") {
      tmpValError.firstName = true;
      validated = false;
    }
    if (lastName === "") {
      tmpValError.lastName = true;
      validated = false;
    }
    if (email === "" || !validateEmail(email)) {
      tmpValError.email = true;
      validated = false;
    }
    if (password === "") {
      tmpValError.password = true;
      validated = false;
    }
    setValidateError(tmpValError);
    return validated;
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <div className="auth-form">
      <div className="auth-form-inner">
        <form action="#" className="ui form">
          <h1 className="">{!error && registered ? content.register.success : content.register.header}</h1>
          <h5 className={!error ? "d-none" : "auth-msg"}>{content.register.error}</h5>
          <h5 className={!error && registered ? "register-confirmed auth-msg" : "d-none"}>{content.register.email}</h5>
          <h5 className={error || registered ? "d-none" : ""}>{content.register.subheader}</h5>
          <div className={!error && registered ? "d-none" : ""}>
            <button
              className={`second-button w-100 mb-2 ${appRole === "buyer" ? "clicked" : ""} ${validateError.appRole ? "val-error" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setValidateError({ ...validateError, appRole: false });
                setAppRole("buyer");
              }}
            >
              {content.register.tenant}
            </button>
            <button
              className={`second-button w-100 mb-3 ${appRole === "seller" ? "clicked" : ""} ${validateError.appRole ? "val-error" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setValidateError({ ...validateError, appRole: false });
                setAppRole("seller");
              }}
            >
              {content.register.landlord}
            </button>
            <div className="field">
              <div className="row ">
                <div className="col">
                  <input
                    className={validateError.firstName ? "val-error" : ""}
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => {
                      setValidateError({ ...validateError, firstName: false });
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="col">
                  <input
                    className={validateError.lastName ? "val-error" : ""}
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => {
                      setValidateError({ ...validateError, lastName: false });
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <input
                className={validateError.email ? "val-error" : ""}
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setValidateError({ ...validateError, email: false });
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="field">
              <input
                className={validateError.password ? "val-error" : ""}
                type="text"
                placeholder="Password"
                onChange={(e) => {
                  setValidateError({ ...validateError, password: false });
                  setPassword(e.target.value);
                }}
              />
            </div>
            <Row>
              <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} className="mt-2 mb-2">
                <button className="second-button submit w-100" type="submit" onClick={onSubmit}>
                  {content.register.btn}
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserRegisterPage;
