import React from "react";

const Dropdown = ({ content, value, setValue }) => {
  return (
    <div className="dropdown text-field w-input">
      <button className="dropbtn">{value || content.label}</button>
      <div className="dropdown-content">
        {content.options.map((el, i) => {
          return (
            <div key={i} onClick={() => setValue(el)}>
              {el}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
