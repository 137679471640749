import React from "react";
import { Link } from "react-router-dom";
import FormatPhone from "../hooks/Formatting";
import { ProfileBio as content } from "../content";

const ProfileBio = ({ user, notMe }) => {
  return (
    <div className="wf-section">
      <div className="w-container">
        <div className="w-layout-grid grid-10">
          <div id="w-node-_4fb76d82-3792-529b-f8c1-0d98dc935a2b-65674ccc">
            <img src="/images/undraw_suburbs_8b83-1.svg" loading="lazy" alt="" className="image-6" />
          </div>
          <div id="w-node-b03b4af7-8e1e-8ce6-983e-93edbac8e293-65674ccc" className="div-block-28">
            <div className={notMe ? "d-none" : "text-block-3 profile"}>{content.welcomeMsg}</div>
            <h1 className="heading-3">
              {user.firstName} <br />
              {user.lastName}
            </h1>
            <div className={notMe ? "d-none" : ""}>
              {user.AppRole === "buyer" ? (
                <Link to="/listings/show">
                  <span className="button3">{content.welcomeBtn.buyer}</span>
                </Link>
              ) : (
                <Link to="/listings/add">
                  <span className="button3">{content.welcomeBtn.seller}</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="shadow2 profile w-container">
        <div className="w-layout-grid grid-11">
          <div id="w-node-f8b90900-0314-a097-9194-bc575d3ddf21-65674ccc">
            <div className={user.firstName || user.lastName ? "div-block-9" : "d-none"}>
              <div className="listing-icon profile w-embed">
                <i className="fas fa-signature"></i>
              </div>
              <div className="listing-text profile">
                {user.firstName} {user.lastName}
              </div>
            </div>
            <div className={user.phone ? "div-block-9" : "d-none"}>
              <div className="listing-icon profile w-embed">
                <i className="fas fa-phone"></i>
              </div>
              <div className="listing-text profile">{FormatPhone(user.phone)}</div>
            </div>
            <div className={user.email ? "div-block-9" : "d-none"}>
              <div className="listing-icon profile w-embed">
                <i className="far fa-envelope"></i>
              </div>
              <div className="listing-text profile">{user.email}</div>
            </div>
            <div className={user.AppRole ? "div-block-9" : "d-none"}>
              <div className="listing-icon profile w-embed">
                <i className="far fa-handshake"></i>
              </div>
              <div className="listing-text profile">
                {user.AppRole === "buyer" && "tenant"}
                {user.AppRole === "seller" && "landlord"}
              </div>
            </div>
          </div>
          <div id="w-node-_87fdf5d4-1135-7983-089f-a0932af7a5be-65674ccc">
            <p className="paragraph">{user.bio || (user.AppRole === "buyer" && content.defaultBio.buyer) || (user.AppRole === "seller" && content.defaultBio.seller)}</p>
          </div>
          <div id="w-node-_0ed46d85-4eb7-d5cd-d531-3575b328435b-65674ccc" className={notMe ? "d-none" : "div-block-29"}>
            <Link to="/profile/edit/1">
              <span className="button3 grey">{content.passwordBtn}</span>
            </Link>
            <Link to="/profile/edit/0">
              <span className="button3">{content.editBtn}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBio;
