import React from "react";
import ListingsListItem from "./ListingsListItem";
import { ProfileListings as content } from "../content";

const ProfileListings = ({ listings, user }) => {
  return (
    <div className="wf-section">
      <div className="div-block-22"></div>
      <div className="div-block-24">
        <div className="container-3 w-container">
          <h1 className={listings.length ? "heading-5" : "d-none"}>
            <span className={user.firstName ? "text-span-2" : "d-none"}>{user.firstName}'s </span>
            {user.AppRole === "buyer" ? content.listingHeader.buyer : content.listingHeader.seller}
          </h1>
          <div className="w-layout-grid grid-8">
            {listings.map((item, i) => {
              return <ListingsListItem key={i} data={item} like edit trash />;
            })}
          </div>
        </div>
      </div>
      <div className="div-block-23"></div>
    </div>
  );
};

export default ProfileListings;
