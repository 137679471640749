import React, { useState, useEffect } from "react";
import { PhotoDelete as content } from "../content";

const PhotoUploadDisplay = ({ photos, removeImages, uploadFilter }) => {
  const [photoList, setPhotoList] = useState([]);

  //   build list of photos
  useEffect(() => {
    var tmp = [];
    for (let i = 0; i < photos.length; i++) {
      tmp.push(URL.createObjectURL(photos[i]));
    }
    setPhotoList(tmp);
  }, [photos]);

  return (
    <>
      {photoList.map((el, i) => {
        if (uploadFilter.includes(i)) {
          return (
            <>
              <div className="div-block-33 shadow2">
                <img loading="lazy" key={i} src={el} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" alt="" className="image-8" />
                <span
                  className="button pt-2 pb-2"
                  onClick={() => {
                    removeImages(i);
                  }}
                >
                  {content.delete}
                </span>
              </div>
            </>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default PhotoUploadDisplay;
