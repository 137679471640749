import React from "react";
import { FindAHome as content } from "../content";

const FindAHome = () => {
  return (
    <section id="cards-section" className="cards-section wf-section">
      <div className="centered-container w-container">
        <div id="w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189031-65674ccc" className="div-block-31 shadow2">
          <h2>{content.header}</h2>
          <p className="paragraph mb-4">{content.subHeader}</p>
          <div className="cards-image-mask w-80 mt-4 smaller">
            <img src="/images/undraw_Opened_re_i38e.svg" alt="" className="cards-image " />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindAHome;
