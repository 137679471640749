import React from "react";
import axios from "axios";
import { admin_email } from "../variables";
import { ContactForm as content } from "../content";

const API_PATH = "/php/contactForm.php";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: localStorage.getItem("firstName") ? `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}` : "",
      email: localStorage.getItem("email"),
      emailTo: this.props.emailTo ? `${this.props.emailTo}` : admin_email,
      message: this.props.message ? `${this.props.message}` : "",
      checkbox: false,
      errors: [false, false, false, false],
      mailSent: false,
      error: null,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: this.state,
      })
        .then((result) => {
          this.setState({
            mailSent: result.data.sent,
          });
          this.clearForm();
        })
        .catch((error) => {
          this.setState({ error: error.message });
          this.clearForm();
        });
    }
  };

  validate = () => {
    const { name, email, message, checkbox } = this.state;
    const tmpErr = [false, false, false, false];
    let result = true;

    if (!name) tmpErr[0] = true;
    if (!this.validateEmail(email)) tmpErr[1] = true;
    if (!message) tmpErr[2] = true;
    if (!checkbox) tmpErr[3] = true;
    this.setState({ errors: tmpErr });
    tmpErr.map((el) => {
      if (el) {
        result = false;
        return true;
      }
      return false;
    });
    return result;
  };

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.setError(e.target.value, -0);
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
    this.setError(e.target.value, 1);
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
    this.setError(e.target.value, 2);
  };

  onCheckBoxChange = (e) => {
    this.setState({ checkbox: e.target.checked });
    this.setError(e.target.checked, 3);
  };

  setError = (value, index) => {
    const tmp = this.state.errors.map((el, i) => (i === index ? !value : el));
    this.setState({ errors: tmp });
  };

  clearForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      checkbox: false,
      errors: [false, false, false, false],
    });
  };

  render() {
    return (
      <div className={`w-form `}>
        <form action="#" id="email-form" name="email-form" data-name="Email Form" className={this.state.mailSent || this.state.error ? "d-none" : ""}>
          <div className="w-layout-grid grid-14 contact">
            <input
              type="text"
              maxLength="256"
              name="Name"
              data-name="Name"
              id="Name-3"
              placeholder={content.placeholder.name}
              onChange={(e) => this.onNameChange(e)}
              value={this.state.name}
              className={`text-field w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffe9-56c17883 w-input ${this.state.errors[0] ? "error" : ""}`}
            />

            <input
              type="email"
              className={`text-field w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffea-56c17883 w-input ${this.state.errors[1] ? "error" : ""}`}
              maxLength="256"
              name="email"
              data-name="email"
              placeholder={content.placeholder.email}
              onChange={(e) => this.onEmailChange(e)}
              value={this.state.email}
              id="email-8"
            />
            <textarea
              name="message"
              maxLength="5000"
              id="message"
              placeholder={content.placeholder.msg}
              data-name="message"
              onChange={(e) => this.onMessageChange(e)}
              value={this.state.message}
              className={`text-field text-input w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffee-56c17883 w-input ${this.state.errors[2] ? "error" : ""}`}
            />
          </div>
          <input type="submit" value={content.btn} className="button3 listing-button" onClick={(e) => this.handleFormSubmit(e)} />
          <div className="mt-4 text-center ">
            <div className="ui toggle checkbox">
              <input type="checkbox" name="public" onChange={(e) => this.onCheckBoxChange(e)} className="coloring" />
              <label className={` ${this.state.errors[3] ? "error" : ""}`}></label>
            </div>
            <p>{this.state.checkbox ? content.captcha.on : content.captcha.off}</p>
          </div>
        </form>
        <div className="text-center mt-4">
          {this.state.mailSent && (
            <>
              <h1>{content.success.header}</h1>
              <h4>{content.success.subheader}</h4>
            </>
          )}
          {this.state.error && (
            <>
              <h1>{content.error.header}</h1>
              <h4>
                {content.error.subheader} {this.state.emailTo}
              </h4>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ContactForm;
