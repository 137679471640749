import React, { useState, useEffect } from "react";
import ProfileBio from "./ProfileBio";
import Loader from "./Loader";
import ErrorPage from "./ErrorPage";
import { AnyUserProfilePage as content } from "../content";
import bellwoods from "../apis/bellwoods";

const AnyUserProfilePage = ({ match }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  // Get the logged in user
  useEffect(() => {
    bellwoods
      .get(`/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  if (error) return <ErrorPage header={content.header} subheader={content.subheader} />;
  if (!user) return <Loader />;
  return (
    <div className="anyUser">
      <ProfileBio user={user} notMe={true} />
    </div>
  );
};

export default AnyUserProfilePage;
