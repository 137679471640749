import React, { useState } from "react";
import ListingsListItem from "./ListingsListItem";
import Loader from "./Loader";
import ListingsNoResults from "./ListingsNoResults";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorPage from "./ErrorPage";
import Pagination from "./Pagination";

const ListingsList = ({ listings, error, theme, page, setPage, listingsTotal, pageLength }) => {
  if (listings && listings.length) {
    return (
      <div className="container">
        <Row className="listing-list">
          {listings.map((item, index) => {
            return (
              <Col key={index} xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 0 }} className="mt-4">
                <ListingsListItem data={item} like edit trash theme={theme ? theme : "dark"} />
              </Col>
            );
          })}
        </Row>
        <Pagination length={listingsTotal} page={page} setPage={setPage} step={pageLength} />
      </div>
    );
  } else if (listings) {
    return <ListingsNoResults />;
  } else if (error) {
    return <ErrorPage />;
  } else {
    return <Loader />;
  }
};

export default ListingsList;
