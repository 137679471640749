import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Navigation from "./Navigation";
import LandingPage from "./LandingPage";
import ListingsPage from "./ListingsPage";
import ListingPage from "./ListingPage";
import ContactPage from "./ContactPage";
import Footer from "./Footer";
import ListingAddPage from "./ListingAddPage";
import ListingEditPage from "./ListingEditPage";
import PasswordForgot from "./PasswordForgot";
import PasswordReset from "./PasswordReset";
import UserProfilePage from "./UserProfilePage";
import AnyUserProfilePage from "./AnyUserProfilePage";
import UserProfileEditPage from "./UserProfileEditPage";
import RedirectEmailConfirm from "./RedirectEmailConfirm";
import AuthPage from "./AuthPage";

import "../css/liamy-a06e0d-956b48f4ee970e.webflow.css";
import "../css/normalize.css";
import "../css/webflow.css";
import "../css/Styles.css";
import ErrorPage from "./ErrorPage";

function App() {
  return (
    <Router>
      <Navigation />
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/listings/show" exact component={ListingsPage} />
          <Route path="/listings/show/:id" exact component={ListingPage} />
          <Route path="/listings/add" exact component={ListingAddPage} />
          <Route path="/listings/edit/:id" exact component={ListingEditPage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/auth/:state" exact component={AuthPage} />
          <Route path="/profile" exact component={UserProfilePage} />
          <Route path="/profile/user/:id" exact component={AnyUserProfilePage} />
          <Route path="/password-forgot" exact component={PasswordForgot} />
          <Route path="/password-reset" exact component={PasswordReset} />
          <Route path="/profile/edit/:i" exact component={UserProfileEditPage} />
          <Route path="/profile/edit/" exact component={UserProfileEditPage} />
          <Route path="/auth/email-confirmation/:confirmation" exact component={RedirectEmailConfirm} />
          {/* error route */}
          <Route component={ErrorPage} />
        </Switch>
      </ScrollToTop>
      <Footer />
    </Router>
  );
}

export default App;
