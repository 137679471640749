import React from "react";
import { Link } from "react-router-dom";

const ListingEdit = ({ id }) => {
  return (
    <Link to={`/listings/edit/${id}`}>
      <i className="far fa-edit zoom"></i>
    </Link>
  );
};

export default ListingEdit;
