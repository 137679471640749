import React, { useState } from "react";
import Loader from "./Loader";
import PhotoDelete from "./PhotoDelete";

const PhotoCurrentDisplay = ({ photos }) => {
  const [photoFilter, setPhotoFilter] = useState([]);

  const handleDelete = (id) => {
    setPhotoFilter([...photoFilter, id]);
  };
  if (photos) {
    return <div className={`w-layout-grid grid-16`}>{photos.map((el, i) => (!photoFilter.includes(el.id) ? <Photo key={i} pic={el} handleDelete={handleDelete} /> : null))}</div>;
  } else {
    return <Loader />;
  }
};

const Photo = ({ pic, handleDelete }) => {
  return (
    <div className="div-block-33 shadow2">
      <img src={pic.url} loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" alt="" className="image-8" />
      <PhotoDelete id={pic.id} handleDelete={handleDelete} />
    </div>
  );
};

export default PhotoCurrentDisplay;
