import React from "react";
import ContactForm from "./ContactForm";
import { ContactPage as content } from "../content";
const ContactPage = () => {
  return (
    <div className="wf-section contact-page">
      <div className="container-4 w-container">
        <div className="w-layout-grid grid-15">
          <div id="w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffd7-56c17883" className="div-block-28 form">
            <div className="text-block-3 profile">{content.subheader}</div>
            <h1 className="heading-3">{content.header}</h1>
          </div>
          <img src="/images/undraw_Opened_re_i38e.svg" loading="lazy" id="w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffe0-56c17883" alt="" className="image-7" />
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactPage;
