import React, { useState, useEffect } from "react";
import bellwoods from "../apis/bellwoods";
import Loader from "./Loader";
import Likes from "./Likes";

import ListingsMap from "./ListingsMap";
import Views from "./Views";
import LightBoxCarousel from "./LightBoxCarousel";
import FormatPhone from "../hooks/Formatting";
import ListingEdit from "./ListingEdit";
import ListingDelete from "./ListingDelete";
import ListingMessageForm from "./ListingMessageForm";
import ErrorPage from "./ErrorPage";
import { ListingPage as content } from "../content";
import history from "../history";

const ListingPage = ({ match }) => {
  const [listing, setListing] = useState(null);
  const [error, setError] = useState(false);
  const [showAdminContact, setShowAdminContact] = useState(false);
  const [showLandlordContact, setShowLandlordContact] = useState(false);
  //get all posts on component load
  useEffect(() => {
    bellwoods
      .get(`/listings/${match.params.id}`)
      .then((response) => {
        setListing(response.data);
        //create a view
        bellwoods.post(`/views/`, {
          listing: [match.params.id],
        });
      })
      .catch((error) => {
        setError(error);
      });
  }, [match.params.id]);

  const onListingDelete = () => {
    history.push("/profile");
    window.location.reload(true);
  };

  if (!error && listing) {
    return (
      <div className="wf-section">
        <div className="container w-container">
          <div className={listing.Owner === localStorage.getItem("userId") ? "div-block-3" : "d-none"}>
            <div className="button3 grey">
              <ListingEdit id={listing.id} />
            </div>
            <div className="button3">
              <ListingDelete listing={listing} onSuccess={onListingDelete} />
            </div>
          </div>
          <div className="div-block-12">
            <Likes listing={true} listingId={listing.id} />
            <Views listing={true} listingId={listing.id} />
          </div>

          <div className="w-layout-grid grid">
            <div data-animation="slide" data-duration="500" data-infinite="1" id="w-node-_9f012e97-d64a-9291-1569-4e99e24d2b5e-296caa15" className="slider w-slider listing-slider">
              <LightBoxCarousel photos={listing.Photos} />
            </div>
            <div id="w-node-_5c9b2f0c-7c28-6c42-9800-7bde58294640-296caa15" className="div-block-11">
              <div className="div-block-10">
                <div>$</div>
                <div className="price-value">{listing.Price}</div>
                <div className="text-block">{content.priceUnit}</div>
              </div>
              <h1 className="address">{listing.Address}</h1>
              <div className="w-layout-grid grid-3">
                <div className="div-block-9">
                  <div className="listing-value">{listing.Beds}</div>
                  <div className="listing-icon w-embed">
                    <i className="fas fa-bed"></i>
                  </div>
                  <div className="listing-text">{content.beds}</div>
                </div>
                <div className="div-block-9">
                  <div className="listing-value">{listing.Baths}</div>
                  <div className="listing-icon w-embed">
                    <i className="fas fa-bath"></i>
                  </div>
                  <div className="listing-text">{content.baths}</div>
                </div>
                <div className="div-block-9">
                  <div className="listing-value">{listing.ParkingSpots}</div>
                  <div className="listing-icon w-embed">
                    <i className="fas fa-car"></i>
                  </div>
                  <div className="listing-text">{content.parking}</div>
                </div>
                <div className="div-block-9">
                  <div className="listing-value">{listing.Size}</div>
                  <div className="listing-icon w-embed">
                    <strong>
                      ft<sup>2</sup>
                    </strong>
                  </div>
                  <div className="listing-text">{content.size}</div>
                </div>
                <div className="div-block-9">
                  <div className="listing-value">{listing.LeaseLength}</div>
                  <div className="listing-icon w-embed">
                    <i className="far fa-clock"></i>
                  </div>
                  <div className="listing-text">{content.months}</div>
                </div>
                <div className="div-block-9">
                  <div className="listing-icon w-embed">
                    <i className="far fa-building"></i>
                  </div>
                  <div className="listing-text">{listing.PropertyType}</div>
                </div>
              </div>
              <p className="description">{listing.Description}</p>
            </div>
            <div id="w-node-_154a121b-6f1d-ff6b-5b79-9a1f1fc8607e-296caa15">
              <div className="w-layout-grid grid-4">
                {/* <div id="w-node-f8d9e038-d363-5516-4da1-886a914efe44-296caa15">
                  <div className="text-block-3 special-3">Rent with </div>
                  <div
                    className={showAdminContact ? "solace-button solace-button-clicked" : "solace-button"}
                    onClick={() => {
                      setShowAdminContact(!showAdminContact);
                      setShowLandlordContact(false);
                    }}
                  >
                    <div className="text-block-2">Solace Solutions</div>
                  </div>
                </div> */}

                <div id="w-node-_76e5fcdc-487b-e4b7-c564-f36e20b5176c-296caa15">
                  <div className={listing.ContactPhone ? "div-block-9" : "d-none"}>
                    <div className="listing-icon w-embed">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="listing-text">{FormatPhone(listing.ContactPhone)}</div>
                  </div>
                  <div className={listing.ContactEmail ? "div-block-9" : "d-none"}>
                    <div className="listing-icon w-embed">
                      <i className="far fa-envelope"></i>
                    </div>
                    <div className="listing-text">{listing.ContactEmail}</div>
                  </div>
                </div>
                <div id="w-node-a63548a9-822d-47fb-d726-ce3e31096351-296caa15" className={listing.ContactEmail ? "" : "d-none"}>
                  <div className="text-block-3 special-3">{content.msgBtn.heading}</div>
                  <div
                    className={showLandlordContact ? "solace-button solace-button-clicked" : "solace-button"}
                    onClick={() => {
                      setShowAdminContact(false);
                      setShowLandlordContact(!showLandlordContact);
                    }}
                  >
                    <div className="text-block-2">{content.msgBtn.btn}</div>
                  </div>
                </div>
              </div>
            </div>
            <div id="w-node-_154a121b-6f1d-ff6b-5b79-9a1f1fc8607e-296caa15">
              <div className={showAdminContact ? "" : "d-none"}>
                <ListingMessageForm showTo={true} listings={[listing]} />
              </div>
              <div className={showLandlordContact ? "" : "d-none"}>
                <ListingMessageForm showTo={true} emailTo={listing.ContactEmail} listings={[listing]} />
              </div>
            </div>
            <div id="w-node-_20ee37f1-3eb3-3170-1b8b-f53a8de55a17-296caa15">
              <div className="listing-conditionals shadow2">
                <div className="check w-embed">
                  <i className="fas fa-check"></i>
                </div>
                <div className="w-layout-grid grid-5">
                  <div className={listing.Pets ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-paw"></i>
                    </div>
                    <div className="listing-text">{content.pets}</div>
                  </div>
                  <div className={listing.Smoking ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-smoking"></i>
                    </div>
                    <div className="listing-text">{content.smoking}</div>
                  </div>
                  <div className={listing.Dishwasher ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-utensils"></i>
                    </div>
                    <div className="listing-text">{content.dishwasher}</div>
                  </div>
                  <div className={listing.LaundryEnsuite ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-tshirt"></i>
                    </div>
                    <div className="listing-text">{content.laundryEnsuite}</div>
                  </div>
                  <div className={listing.LaundryFacilities ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-socks"></i>
                    </div>
                    <div className="listing-text">{content.laundryFacilities}</div>
                  </div>
                  <div className={listing.Gym ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-dumbbell"></i>
                    </div>
                    <div className="listing-text">{content.gym}</div>
                  </div>
                  <div className={listing.WheelchairAccess ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-wheelchair"></i>
                    </div>
                    <div className="listing-text">{content.accessible}</div>
                  </div>
                  <div className={listing.Balcony ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-umbrella-beach"></i>
                    </div>
                    <div className="listing-text">{content.balcony}</div>
                  </div>
                  <div className={listing.Fireplace ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-fire"></i>
                    </div>
                    <div className="listing-text">{content.fireplace}</div>
                  </div>
                  <div className={listing.Pool ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-swimmer"></i>
                    </div>
                    <div className="listing-text">{content.pool}</div>
                  </div>
                  <div className={listing.Furnished ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-chair"></i>
                    </div>
                    <div className="listing-text">{content.furnished}</div>
                  </div>
                </div>
              </div>
              <div className="listing-conditionals red shadow2">
                <div className="check red w-embed">
                  <i className="fas fa-times"></i>
                </div>
                <div className="w-layout-grid grid-5">
                  <div className={!listing.Pets ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-paw"></i>
                    </div>
                    <div className="listing-text">{content.pets}</div>
                  </div>
                  <div className={!listing.Smoking ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-smoking"></i>
                    </div>
                    <div className="listing-text">{content.smoking}</div>
                  </div>
                  <div className={!listing.Dishwasher ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-utensils"></i>
                    </div>
                    <div className="listing-text">{content.dishwasher}</div>
                  </div>
                  <div className={!listing.LaundryEnsuite ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-tshirt"></i>
                    </div>
                    <div className="listing-text">{content.laundryEnsuite}</div>
                  </div>
                  <div className={!listing.LaundryFacilities ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-socks"></i>
                    </div>
                    <div className="listing-text">{content.laundryFacilities}</div>
                  </div>
                  <div className={!listing.Gym ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-dumbbell"></i>
                    </div>
                    <div className="listing-text">{content.gym}</div>
                  </div>
                  <div className={!listing.WheelchairAccess ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-wheelchair"></i>
                    </div>
                    <div className="listing-text">{content.accessible}</div>
                  </div>
                  <div className={!listing.Balcony ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-umbrella-beach"></i>
                    </div>
                    <div className="listing-text">{content.balcony}</div>
                  </div>
                  <div className={!listing.Fireplace ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-fire"></i>
                    </div>
                    <div className="listing-text">{content.fireplace}</div>
                  </div>
                  <div className={!listing.Pool ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-swimmer"></i>
                    </div>
                    <div className="listing-text">{content.pool}</div>
                  </div>
                  <div className={!listing.Furnished ? "div-block-9" : "d-none"}>
                    <div className="listing-icon black w-embed">
                      <i className="fas fa-chair"></i>
                    </div>
                    <div className="listing-text">{content.furnished}</div>
                  </div>
                </div>
              </div>
            </div>

            <div id="w-node-a27b4229-8099-7087-f90e-d484a8da931f-296caa15" className="div-block-17">
              <div
                data-enable-touch="true"
                data-enable-scroll="true"
                data-widget-style="roadmap"
                data-widget-zoom="12"
                data-widget-latlng=""
                data-widget-tooltip=""
                className="map shadow2 w-widget w-widget-map main-map-cont"
              >
                <ListingsMap locations={[{ lat: listing.Latitude, lng: listing.Longitude }]} />
              </div>
            </div>
            <div
              id="w-node-efae3d37-ed15-b27f-d96f-b52556a812a7-296caa15"
              className={
                listing.InternetIncluded || listing.ElectricityHydroIncluded || listing.SatelliteIncluded || listing.CableIncluded || listing.WaterIncluded || listing.HeatIncluded
                  ? "shadow2"
                  : "d-none"
              }
            >
              <div>
                <div className="text-block-3">{content.utilitiesHeader}</div>
                <div className="div-block-18">
                  <div className="utility listing-text">{listing.InternetIncluded ? content.internet : ""}</div>
                  <div className="utility listing-text">{listing.ElectricityHydroIncluded ? content.electricity : ""}</div>
                  <div className="utility listing-text">{listing.SatelliteIncluded ? content.satellite : ""}</div>
                  <div className="utility listing-text">{listing.CableIncluded ? content.cable : ""}</div>
                  <div className="utility listing-text">{listing.WaterIncluded ? content.water : ""}</div>
                  <div className="utility listing-text">{listing.HeatIncluded ? content.heat : ""}</div>
                </div>
              </div>
            </div>
            <div id="w-node-_2c176fbf-27c5-2e69-db56-8942350eb565-296caa15" className={listing.AmenitiesOther && listing.AmenitiesOther.length ? "shadow2 div-box" : "d-none"}>
              <div>
                <div className="text-block-3">{content.amenitiesHeader}</div>
                <div className="div-block-18">
                  {listing.AmenitiesOther &&
                    listing.AmenitiesOther.map((el, i) => {
                      return (
                        <div key={i} className="utility listing-text">
                          {el}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div id="w-node-_3334a043-4cd4-8053-8d3c-8bea2d50ba8e-296caa15" className={listing.AppliancesOther && listing.AppliancesOther.length ? "shadow2 div-box" : "d-none"}>
              <div>
                <div className="text-block-3">{content.appliancesHeader}</div>
                <div className="div-block-18">
                  {listing.AppliancesOther &&
                    listing.AppliancesOther.map((el, i) => {
                      return (
                        <div key={i} className="utility listing-text">
                          {el}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div id="w-node-_599287c3-f9ba-8002-c314-a9057ca0b297-296caa15" className={listing.ParkingType && listing.ParkingType.length ? "shadow2 div-box" : "d-none"}>
              <div>
                <div className="text-block-3">{content.parkingTypeHeader}</div>
                <div className="div-block-18">
                  <div className="utility listing-text">{listing.ParkingType}</div>
                </div>
              </div>
            </div>
            <div id="w-node-_167063ef-27eb-bdfe-3315-433efbb4aeac-296caa15" className={listing.OutdoorSpace && listing.OutdoorSpace.length ? "shadow2 div-box" : "d-none"}>
              <div>
                <div className="text-block-3">{content.outdoorHeader}</div>
                <div className="div-block-18">
                  {listing.OutdoorSpace &&
                    listing.OutdoorSpace.map((el, i) => {
                      return (
                        <div key={i} className="utility listing-text">
                          {el}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (error) {
    return <ErrorPage />;
  } else {
    return <Loader />;
  }
};

export default ListingPage;
