import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const LightBoxCarousel = ({ photos }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [photoArray, setPhotoArray] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);

  //   Build array of urls
  useEffect(() => {
    if (photos) {
      setPhotoArray(
        photos.map((el) => {
          return el.url;
        })
      );
    }
  }, [photos]);

  return (
    <>
      {/* lightbox modal */}
      {isOpen && (
        <Lightbox
          mainSrc={photoArray[photoIndex]}
          nextSrc={photoArray[(photoIndex + 1) % photoArray.length]}
          prevSrc={photoArray[(photoIndex + photoArray.length - 1) % photoArray.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + photoArray.length - 1) % photoArray.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photoArray.length)}
        />
      )}
      {/* Slider */}

      <div className="mask w-slider-mask">
        {photos.map((el, i) => {
          return (
            <div key={i} className={slideIndex === i ? "current-img w-slide" : "d-none"}>
              <span
                className="lightbox-link w-inline-block w-lightbox"
                onClick={() => {
                  setPhotoIndex(slideIndex);
                  setIsOpen(true);
                }}
              >
                <img
                  src={el.url}
                  loading="lazy"
                  alt={el.name}
                  className="image"
                  srcSet={
                    el.formats &&
                    el.formats.large &&
                    el.formats.medium &&
                    el.formats.small &&
                    el.formats.thumbnail &&
                    `${el.formats.large.url} ${el.formats.large.width}w,
                    ${el.formats.medium.url} ${el.formats.medium.width}w,
                    ${el.formats.small.url} ${el.formats.small.width}w,
                    ${el.formats.thumbnail.url} ${el.formats.thumbnail.width}w`
                  }
                />
              </span>
            </div>
          );
        })}
        {/* no image */}
        <div className={!photos || !photos.length ? "w-slide" : "d-none"}>
          <span className="lightbox-link w-inline-block w-lightbox">
            <img src="/images/noImage.jpeg" loading="lazy" alt="no images" className="image" />
          </span>
        </div>
      </div>
      <div className="w-slider-arrow-left">
        <div className="w-icon-slider-left" onClick={() => setSlideIndex((slideIndex + photoArray.length - 1) % photoArray.length)}></div>
      </div>
      <div className="w-slider-arrow-right">
        <div className="w-icon-slider-right" onClick={() => setSlideIndex((slideIndex + 1) % photoArray.length)}></div>
      </div>
      <div className="w-slider-nav w-round"></div>
    </>
  );
};

export default LightBoxCarousel;
