import React, { useState, useEffect } from "react";
import Carousel3D from "./Carousel3D";

import bellwoods from "../apis/bellwoods";
import { Link } from "react-router-dom";
import qs from "qs";

import { LandingPage as content } from "../content";

const LandingPage = () => {
  const [listings, setListings] = useState([]);

  //get all posts on component load
  useEffect(() => {
    const queryFilters = {};
    queryFilters.Featured = true;

    // consolidate the filters
    const query = qs.stringify({
      _where: [queryFilters],
    });

    bellwoods.get(`/listings?${query}`).then((response) => {
      setListings(response.data);
    });
    // .catch((error) => {
    // });
  }, []);

  // new
  return (
    <>
      <div className="landing-hero wf-section">
        <div className="container-2 w-container">
          <div className="w-layout-grid grid-7">
            <div id="w-node-d6c7e5ea-2acb-6f7b-6558-956e6bc05aba-885d22ed" className="hero-div">
              <Link to={content.landlordButton.url} className="main-button shadow ">
                {content.landlordButton.text}
              </Link>
              <img src="/images/undraw_our_neighborhood_ya1h.svg" loading="lazy" alt="" className="hero-illustration" />
            </div>
            <div className="hero-div">
              <Link to={content.tenantButton.url} className="main-button shadow ">
                {content.tenantButton.text}
              </Link>

              <img src="/images/undraw_coming_home_52ir-1.svg" loading="lazy" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="div-block-22"></div>
        <div className="div-block-24">
          <div className="w-container">
            <Carousel3D listings={listings} />
          </div>
        </div>
        <div className="div-block-23"></div>
      </div>

      <section id="gallery" className="gallery wf-section">
        <div className="centered-container w-container">
          <h2 className="heading-2">
            {content.hamilton.header[0]}
            <span className="text-span">{content.hamilton.header[1]}</span>
            {content.hamilton.header[2]}
          </h2>
          <p>{content.hamilton.subheader}</p>
          <div className="gallery-grid-container">
            <span id="w-node-bd957099-76a2-af3d-7df6-5b77dfc9f4eb-885d22ed" className="w-inline-block w-lightbox">
              <img
                src="/images/sam-6ko_IbCtdhE-unsplash.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 167px, 220px"
                srcSet="/images/sam-6ko_IbCtdhE-unsplash-p-500.jpeg 500w, /images/sam-6ko_IbCtdhE-unsplash-p-800.jpeg 800w, /images/sam-6ko_IbCtdhE-unsplash-p-1080.jpeg 1080w, /images/sam-6ko_IbCtdhE-unsplash-p-1600.jpeg 1600w, /images/sam-6ko_IbCtdhE-unsplash-p-2000.jpeg 2000w, /images/sam-6ko_IbCtdhE-unsplash.jpg 2378w"
                alt=""
                className="image-4"
              />

              <script type="application/json" className="w-json">
                {JSON.stringify({
                  items: [
                    {
                      width: 2378,
                      caption: "",
                      height: 2972,
                      fileName: "615b69249bfbd5756322c6c1_sam-6ko_IbCtdhE-unsplash.jpg",
                      origFileName: "sam-6ko_IbCtdhE-unsplash.jpg",
                      url: "/images/sam-6ko_IbCtdhE-unsplash.jpg",
                      _id: "615b69249bfbd5756322c6c1",
                      type: "image",
                      fileSize: 1228618,
                    },
                  ],
                  group: "landing",
                })}
              </script>
            </span>
            <span className="w-inline-block w-lightbox">
              <img
                src="/images/vivek-trivedi-e9nGua3TPgc-unsplash.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 167px, 220px"
                srcSet="/images/vivek-trivedi-e9nGua3TPgc-unsplash-p-500.jpeg 500w, /images/vivek-trivedi-e9nGua3TPgc-unsplash-p-1080.jpeg 1080w, /images/vivek-trivedi-e9nGua3TPgc-unsplash-p-1600.jpeg 1600w, /images/vivek-trivedi-e9nGua3TPgc-unsplash-p-2000.jpeg 2000w, /images/vivek-trivedi-e9nGua3TPgc-unsplash-p-2600.jpeg 2600w, /images/vivek-trivedi-e9nGua3TPgc-unsplash-p-3200.jpeg 3200w, /images/vivek-trivedi-e9nGua3TPgc-unsplash.jpg 5364w"
                alt=""
                className="image-5"
              />

              <script type="application/json" className="w-json">
                {JSON.stringify({
                  items: [
                    {
                      type: "image",
                      _id: "615b6924c86ddcc79d6023d8",
                      fileName: "615b6924c86ddcc79d6023d8_vivek-trivedi-e9nGua3TPgc-unsplash.jpg",
                      origFileName: "vivek-trivedi-e9nGua3TPgc-unsplash.jpg",
                      width: 5364,
                      height: 3576,
                      fileSize: 2326732,
                      url: "/images/vivek-trivedi-e9nGua3TPgc-unsplash.jpg",
                    },
                  ],
                  group: "landing",
                })}
              </script>
            </span>
            <span className="w-inline-block w-lightbox">
              <img
                src="/images/max-schramp-hDZDV_UolcM-unsplash.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 167px, 220px"
                srcSet="/images/max-schramp-hDZDV_UolcM-unsplash-p-500.jpeg 500w, /images/max-schramp-hDZDV_UolcM-unsplash-p-800.jpeg 800w, /images/max-schramp-hDZDV_UolcM-unsplash-p-1080.jpeg 1080w, /images/max-schramp-hDZDV_UolcM-unsplash-p-1600.jpeg 1600w, /images/max-schramp-hDZDV_UolcM-unsplash-p-2000.jpeg 2000w, /images/max-schramp-hDZDV_UolcM-unsplash-p-2600.jpeg 2600w, /images/max-schramp-hDZDV_UolcM-unsplash-p-3200.jpeg 3200w, /images/max-schramp-hDZDV_UolcM-unsplash.jpg 3659w"
                alt=""
                className="image-5"
              />
              <script type="application/json" className="w-json">
                {JSON.stringify({
                  items: [
                    {
                      width: 3659,
                      caption: "",
                      height: 2744,
                      fileName: "615b6924d9cdad1c75857ca6_max-schramp-hDZDV_UolcM-unsplash.jpg",
                      origFileName: "max-schramp-hDZDV_UolcM-unsplash.jpg",
                      url: "images/max-schramp-hDZDV_UolcM-unsplash.jpg",
                      _id: "615b6924d9cdad1c75857ca6",
                      type: "image",
                      fileSize: 1568310,
                    },
                  ],
                  group: "landing",
                })}
              </script>
            </span>
            <span className="w-inline-block w-lightbox">
              <img
                src="/images/gv-chana-qnaPStdvbJE-unsplash.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 167px, 220px"
                srcSet="/images/gv-chana-qnaPStdvbJE-unsplash-p-500.jpeg 500w, /images/gv-chana-qnaPStdvbJE-unsplash-p-800.jpeg 800w, /images/gv-chana-qnaPStdvbJE-unsplash-p-1080.jpeg 1080w, /images/gv-chana-qnaPStdvbJE-unsplash-p-1600.jpeg 1600w, /images/gv-chana-qnaPStdvbJE-unsplash-p-2000.jpeg 2000w, /images/gv-chana-qnaPStdvbJE-unsplash-p-2600.jpeg 2600w, /images/gv-chana-qnaPStdvbJE-unsplash-p-3200.jpeg 3200w, /images/gv-chana-qnaPStdvbJE-unsplash.jpg 4000w"
                alt=""
                className="image-5"
              />

              <script type="application/json" className="w-json">
                {JSON.stringify({
                  items: [
                    {
                      type: "image",
                      _id: "615b69248ff4e95fb5aed3eb",
                      fileName: "615b69248ff4e95fb5aed3eb_gv-chana-qnaPStdvbJE-unsplash.jpg",
                      origFileName: "gv-chana-qnaPStdvbJE-unsplash.jpg",
                      width: 4000,
                      height: 2250,
                      fileSize: 2734807,
                      url: "/images/gv-chana-qnaPStdvbJE-unsplash.jpg",
                    },
                  ],
                  group: "landing",
                })}
              </script>
            </span>
            <span className="w-inline-block w-lightbox">
              <img
                src="/images/matthew-tyler-ltnXsAJC020-unsplash.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 46vw, (max-width: 991px) 167px, 220px"
                srcSet="/images/matthew-tyler-ltnXsAJC020-unsplash-p-500.jpeg 500w, /images/matthew-tyler-ltnXsAJC020-unsplash-p-800.jpeg 800w, /images/matthew-tyler-ltnXsAJC020-unsplash-p-1080.jpeg 1080w, /images/matthew-tyler-ltnXsAJC020-unsplash-p-1600.jpeg 1600w, /images/matthew-tyler-ltnXsAJC020-unsplash-p-2000.jpeg 2000w, /images/matthew-tyler-ltnXsAJC020-unsplash-p-2600.jpeg 2600w, /images/matthew-tyler-ltnXsAJC020-unsplash-p-3200.jpeg 3200w, /images/matthew-tyler-ltnXsAJC020-unsplash.jpg 3299w"
                alt=""
                className="image-5"
              />

              <script type="application/json" className="w-json">
                {JSON.stringify({
                  items: [
                    {
                      width: 3299,
                      caption: "",
                      height: 4124,
                      fileName: "615b69249d938c396389908b_matthew-tyler-ltnXsAJC020-unsplash.jpg",
                      origFileName: "matthew-tyler-ltnXsAJC020-unsplash.jpg",
                      url: "/images/matthew-tyler-ltnXsAJC020-unsplash.jpg",
                      _id: "615b69249d938c396389908b",
                      type: "image",
                      fileSize: 2249800,
                    },
                  ],
                  group: "landing",
                })}
              </script>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
