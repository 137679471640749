import React from "react";
import Slider from "@material-ui/core/Slider";

const RangeSlider = ({ content, constants, value, valueChange }) => {
  const handleChange = (event, newValue) => {
    valueChange([parseInt(newValue[0]), parseInt(newValue[1])]);
  };

  return (
    <div id="w-node-_386770a4-0249-0999-88cb-0d17559c1958-8cdfb1a7" className="range-slider">
      <div className="div-block-9 app">
        <div className="listing-icon w-embed">
          <i className={content.icon}></i>
        </div>
        <div className="listing-text">{content.label}</div>
      </div>
      <div>
        <div className="w-form">
          <div className="w-layout-grid grid-13">
            <div id="w-node-ff3ce9bc-2f22-157c-038e-9f2c5ceb074a-8cdfb1a7" className="slide-input">
              <Slider value={value} onChange={handleChange} valueLabelDisplay="auto" aria-labelledby="range-slider" min={constants[0]} max={constants[1]} step={constants[2]} />
            </div>
            <input type="number" maxLength="256" data-name="" className="range-input w-input" value={value[0]} onChange={(e) => handleChange(e, [e.target.value, value[1]])} />
            <input type="number" maxLength="256" data-name="" className="range-input w-input" value={value[1]} onChange={(e) => handleChange(e, [value[0], e.target.value])} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
