import React from "react";
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@google/markerclustererplus";
import { MAPS_API_KEY } from "../variables";

const ListingsMap = ({ locations, hoverMarker, clickMarker }) => {
  return (
    <div id="main-map" key={locations}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: MAPS_API_KEY }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          const googleMapRef = map;
          const googleRef = maps;
          let markers =
            locations &&
            locations.map((location) => {
              return new googleRef.Marker({
                position: location,
              });
            });
          markers.map((el, id) => {
            if (typeof hoverMarker === "function") {
              el.addListener("mouseover", () => hoverMarker(id));
              el.addListener("mouseout", () => hoverMarker(null));
            }
            if (typeof clickMarker === "function") {
              el.addListener("click", () => clickMarker(id));
            }
            return null;
          });

          let markerCluster = new MarkerClusterer(map, markers, {
            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            averageCenter: true,
            gridSize: 40,
            maxZoom: 24,
            minimumClusterSize: 2,
          });
        }}
        defaultCenter={locations[0] || { lat: 43.248221, lng: -79.840246 }}
        defaultZoom={12}
        options={{ streetViewControl: true }}
      />
    </div>
  );
};

export default ListingsMap;
