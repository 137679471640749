import React from "react";
import { ListingsNoResults as content } from "../content";

const ListingsNoResults = () => {
  return (
    <div className="noresults">
      <h1>{content.header}</h1>
      <p>{content.subheader}</p>
    </div>
  );
};

export default ListingsNoResults;
