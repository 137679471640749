import React, { useState, useEffect } from "react";
// import { bellwoods } from "../apis/bellwoods";
import bellwoods from "../apis/bellwoods";

import PhotoUploadDisplay from "./PhotoUploadDisplay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckSlider from "./CheckSlider";
import Loader from "./Loader";
import history from "../history";

import { ListingForm as content } from "../content";
import Hamburger from "./Hamburger";
import ListingsMap from "./ListingsMap";
import Dropdown from "./Dropdown";
import CheckSliderMiddle from "./CheckSliderMiddle";
import PhotoCurrentDisplay from "./PhotoCurrentDisplay";
import JsonInput from "./JsonInput";
import GetCoords from "./GetCoords";

const ListingForm = ({ listing }) => {
  const [expanded, setExpanded] = useState([true, false, false, false]);
  const [uploadFilter, setUploadFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressEntered, setAddressEntered] = useState(false);
  const [address, setAddress] = useState(listing ? listing.Address : "");
  const [photos, setPhotos] = useState(listing ? "" : "");
  const [price, setPrice] = useState(listing ? listing.Price : null);
  const [description, setDescription] = useState(listing ? listing.Description : "");
  const [leaseLength, setLeaseLength] = useState(listing ? listing.LeaseLength : null);
  const [contactPhone, setContactPhone] = useState(listing ? listing.ContactPhone : null);
  const [contactEmail, setContactEmail] = useState(listing ? listing.ContactEmail : "");
  const [beds, setBeds] = useState(listing ? listing.Beds : null);
  const [baths, setBaths] = useState(listing ? listing.Baths : null);
  const [pets, setPets] = useState(listing ? listing.Pets : null);
  const [parkingSpots, setParkingSpots] = useState(listing ? listing.ParkingSpots : null);
  const [parkingType, setParkingType] = useState(listing ? listing.ParkingType : "");
  const [size, setSize] = useState(listing ? listing.Size : null);
  const [outdoorSpace, setOutdoorSpace] = useState(listing ? listing.OutdoorSpace : null);
  const [smoking, setSmoking] = useState(listing ? listing.Smoking : null);
  const [dishwasher, setDishwasher] = useState(listing ? listing.Dishwasher : false);
  const [laundryEnsuite, setLaundryEnsuite] = useState(listing ? listing.laundryEnsuite : false);
  const [laundryFacilities, setLaundryFacilities] = useState(listing ? listing.LaundryFacilities : false);
  const [gym, setGym] = useState(listing ? listing.Gym : false);
  const [wheelchairAccess, setWheelchairAccess] = useState(listing ? listing.WheelchairAccess : false);
  const [balcony, setBalcony] = useState(listing ? listing.Balcony : false);
  const [fireplace, setFireplace] = useState(listing ? listing.Fireplace : false);
  const [pool, setPool] = useState(listing ? listing.Pool : false);
  const [amenitiesOther, setAmenitiesOther] = useState(listing ? listing.AmenitiesOther : null);
  const [furnished, setFurnished] = useState(listing ? listing.Furnished : null);
  const [appliancesOther, setAppliancesOther] = useState(listing ? listing.AppliancesOther : null);
  const [heatIncluded, setHeatIncluded] = useState(listing ? listing.HeatIncluded : false);
  const [internetIncluded, setInternetIncluded] = useState(listing ? listing.InternetIncluded : false);
  const [electricityHydroIncluded, setElectricityHydroIncluded] = useState(listing ? listing.ElectricityHydroIncluded : false);
  const [satelliteIncluded, setSatelliteIncluded] = useState(listing ? listing.SatelliteIncluded : false);
  const [waterIncluded, setWaterIncluded] = useState(listing ? listing.WaterIncluded : false);
  const [cableIncluded, setCableIncluded] = useState(listing ? listing.CableIncluded : false);
  const [airConditioning, setAirConditioning] = useState(listing ? listing.AirConditioning : false);
  const [propertyType, setPropertyType] = useState(listing ? listing.PropertyType : "");
  const [longitude, setLongitude] = useState(listing ? listing.Longitude : null);
  const [latitude, setLatitude] = useState(listing ? listing.Latitude : null);
  const [location, setLocation] = useState(listing ? [listing.Latitude, listing.Longitude] : []);
  const [dateListed, setDateListed] = useState(new Date());

  // const [expanded, setExpanded] = useState([true, false, false, false]);
  // const [uploadFilter, setUploadFilter] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [addressEntered, setAddressEntered] = useState(false);
  // const [address, setAddress] = useState(listing ? listing.Address : "");
  // const [photos, setPhotos] = useState(listing ? "" : "");
  // const [price, setPrice] = useState(listing ? listing.Price : "");
  // const [description, setDescription] = useState(listing ? listing.Description : "");
  // const [leaseLength, setLeaseLength] = useState(listing ? listing.LeaseLength : "");
  // const [contactPhone, setContactPhone] = useState(listing ? listing.ContactPhone : "");
  // const [contactEmail, setContactEmail] = useState(listing ? listing.ContactEmail : "");
  // const [beds, setBeds] = useState(listing ? listing.Beds : "");
  // const [baths, setBaths] = useState(listing ? listing.Baths : "");
  // const [pets, setPets] = useState(listing ? listing.Pets : null);
  // const [parkingSpots, setParkingSpots] = useState(listing ? listing.ParkingSpots : "");
  // const [parkingType, setParkingType] = useState(listing ? listing.ParkingType : "");
  // const [size, setSize] = useState(listing ? listing.Size : "");
  // const [outdoorSpace, setOutdoorSpace] = useState(listing ? listing.OutdoorSpace : "");
  // const [smoking, setSmoking] = useState(listing ? listing.Smoking : null);
  // const [dishwasher, setDishwasher] = useState(listing ? listing.Dishwasher : false);
  // const [laundryEnsuite, setLaundryEnsuite] = useState(listing ? listing.laundryEnsuite : false);
  // const [laundryFacilities, setLaundryFacilities] = useState(listing ? listing.LaundryFacilities : false);
  // const [gym, setGym] = useState(listing ? listing.Gym : false);
  // const [wheelchairAccess, setWheelchairAccess] = useState(listing ? listing.WheelchairAccess : false);
  // const [balcony, setBalcony] = useState(listing ? listing.Balcony : false);
  // const [fireplace, setFireplace] = useState(listing ? listing.Fireplace : false);
  // const [pool, setPool] = useState(listing ? listing.Pool : false);
  // const [amenitiesOther, setAmenitiesOther] = useState(listing ? listing.AmenitiesOther : null);
  // const [furnished, setFurnished] = useState(listing ? listing.Furnished : null);
  // const [appliancesOther, setAppliancesOther] = useState(listing ? listing.AppliancesOther : null);
  // const [heatIncluded, setHeatIncluded] = useState(listing ? listing.HeatIncluded : false);
  // const [internetIncluded, setInternetIncluded] = useState(listing ? listing.InternetIncluded : false);
  // const [electricityHydroIncluded, setElectricityHydroIncluded] = useState(listing ? listing.ElectricityHydroIncluded : false);
  // const [satelliteIncluded, setSatelliteIncluded] = useState(listing ? listing.SatelliteIncluded : false);
  // const [waterIncluded, setWaterIncluded] = useState(listing ? listing.WaterIncluded : false);
  // const [cableIncluded, setCableIncluded] = useState(listing ? listing.CableIncluded : false);
  // const [airConditioning, setAirConditioning] = useState(listing ? listing.AirConditioning : false);
  // const [propertyType, setPropertyType] = useState(listing ? listing.PropertyType : "");
  // const [longitude, setLongitude] = useState(listing ? listing.Longitude : null);
  // const [latitude, setLatitude] = useState(listing ? listing.Latitude : null);
  // const [location, setLocation] = useState(listing ? [listing.Latitude, listing.Longitude] : []);
  // const [dateListed, setDateListed] = useState(new Date());
  const [validateError, setValidateError] = useState({
    address: false,
    contactPhone: false,
    contactEmail: false,
    beds: false,
    baths: false,
    size: false,
    price: false,
    leaseLength: false,
    dateListed: false,
    coords: false,
  });

  const validateForm = () => {
    var validated = true;
    var tmpValError = {};
    if (latitude === null || longitude === null || latitude === 0 || longitude === 0) {
      tmpValError.coords = true;
      validated = false;
    }

    if (address === "" || address === null) {
      tmpValError.address = true;
      validated = false;
    }
    if (contactPhone === "" || contactPhone === null) {
      tmpValError.contactPhone = true;
      validated = false;
    }
    if (beds === "" || beds === null) {
      tmpValError.beds = true;
      validated = false;
    }
    if (baths === "" || baths === null) {
      tmpValError.baths = true;
      validated = false;
    }
    if (size === "" || size === null) {
      tmpValError.size = true;
      validated = false;
    }
    if (price === "" || price === null) {
      tmpValError.price = true;
      validated = false;
    }
    if (leaseLength === "" || leaseLength === null) {
      tmpValError.leaseLength = true;
      validated = false;
    }
    if (dateListed === "" || dateListed === null) {
      tmpValError.dateListed = true;
      validated = false;
    }

    if (contactEmail === "" || !validateEmail(contactEmail)) {
      tmpValError.contactEmail = true;
      validated = false;
    }

    setValidateError(tmpValError);
    return validated;
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    const formData = new FormData();

    const data = {};
    data["users_permissions_user"] = localStorage.getItem("userId");
    data["Address"] = address;
    data["Price"] = price;
    data["Description"] = description;
    data["LeaseLength"] = leaseLength;
    data["ContactPhone"] = contactPhone;
    data["ContactEmail"] = contactEmail;
    data["Beds"] = beds;
    data["Baths"] = baths;
    data["Pets"] = pets;
    data["ParkingSpots"] = parkingSpots;
    data["ParkingType"] = parkingType;
    data["Size"] = size;
    data["OutdoorSpace"] = outdoorSpace;
    data["Smoking"] = smoking;
    data["AmenitiesOther"] = amenitiesOther;
    data["Furnished"] = furnished;

    data["Dishwasher"] = dishwasher;
    data["LaundryEnsuite"] = laundryEnsuite;
    data["LaundryFacilities"] = laundryFacilities;
    data["Gym"] = gym;
    data["WheelchairAccess"] = wheelchairAccess;
    data["Balcony"] = balcony;
    data["Fireplace"] = fireplace;
    data["Pool"] = pool;

    data["AppliancesOther"] = appliancesOther;

    data["HeatIncluded"] = heatIncluded;
    data["InternetIncluded"] = internetIncluded;
    data["ElectricityHydroIncluded"] = electricityHydroIncluded;
    data["SatelliteIncluded"] = satelliteIncluded;
    data["WaterIncluded"] = waterIncluded;
    data["CableIncluded"] = cableIncluded;

    data["AirConditioning"] = airConditioning;
    data["PropertyType"] = propertyType;
    data["Longitude"] = longitude;
    data["Latitude"] = latitude;

    // Only add these fields if its a new listing
    if (!listing) {
      data["DateListed"] = dateListed;
    }
    // Upload Photos
    for (let i = 0; i < photos.length; i++) {
      if (uploadFilter.includes(i)) {
        const file = photos[i];
        formData.append(`files.Photos`, file, file.name);
      }
    }
    // Upload Text Data
    formData.append("data", JSON.stringify(data));

    console.log(data);
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1] + "\n");
    }

    if (listing) {
      bellwoods
        .put(`/listings/${listing.id}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          // go back to user profile
          history.push(`/listings/show/${listing.id}`);
          window.location.reload(true);
        })
        .catch((error) => {
          setLoading(false);
          // go to error page
          history.push(`/error`);
          window.location.reload(true);
        });
    } else {
      //add a listing
      bellwoods
        .post("/listings/", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          history.push(`/profile`);
          window.location.reload(true);
        })
        .catch((error) => {
          setLoading(false);
          // go to error page
          console.log(error);
          history.push(`/error`);
          window.location.reload(true);
        });
    }
  };

  //   Helper function to click images to remove them
  const removeImages = (i) => {
    setUploadFilter(uploadFilter.filter((item) => item !== i));
  };

  const changeExpanded = (index) => {
    var tmp = [...expanded];
    expanded.map((el, i) => {
      if (i === index) {
        tmp[i] = true;
      } else {
        tmp[i] = false;
      }
      return null;
    });
    setExpanded(tmp);
  };

  useEffect(() => {
    setLocation([{ lat: latitude, lng: longitude }]);
  }, [latitude, longitude]);

  const renderMap = () => {
    if (latitude && longitude) {
      return (
        <div className="form-map shadow">
          <ListingsMap locations={location} />
        </div>
      );
    } else {
      return null;
    }
  };

  const addressEnter = (key) => {
    if (key === "Enter") {
      setAddressEntered(true);
    } else {
      setAddressEntered(false);
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="wf-section">
        <div className="container-4 w-container">
          <div className="w-layout-grid grid-15">
            <div id="w-node-de352948-ded4-94a7-5d60-75d9b08c6c38-be368e20" className="div-block-28 form">
              <div className="text-block-3 profile">{listing ? content.subheader.edit : content.subheader.new}</div>
              <h1 className="heading-3">
                {listing ? content.header.edit[0] : content.header.new[0]}
                <br />
                {listing ? content.header.edit[1] : content.header.new[1]}
              </h1>
              <a href={content.btn.url} target="_blank" rel="noreferrer" className="button3">
                {content.btn.label}
              </a>
            </div>
            <img src="/images/undraw_Add_files_re_v09g.svg" loading="lazy" id="w-node-_58df4415-c17e-6386-4959-4313e968e8e9-be368e20" alt="" className="image-7" />
          </div>
          <div className="w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              action="#"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="form-sub-title" onClick={() => changeExpanded(0)}>
                <h2 className="form-heading">{content.headings[0]}</h2>
                <Hamburger clicked={expanded[0]} onHamClick={() => changeExpanded(0)} />
              </div>
              <div className={`w-layout-grid grid-14 ${expanded[0] ? "form-block-show" : "form-block-hide"}`}>
                <input
                  type="text"
                  className={`text-field w-node-b7b62d2f-47af-fb4d-60d5-c9ddebfb86a7-be368e20 w-input ${validateError.address ? "val-error" : ""}`}
                  maxLength="256"
                  name="address"
                  data-name="address"
                  id="address"
                  placeholder={content.address}
                  value={address}
                  onChange={(e) => {
                    setValidateError({ ...validateError, address: false });
                    setAddress(e.target.value);
                  }}
                  onKeyDown={(e) => addressEnter(e.key)}
                  required
                />
                <GetCoords
                  address={address}
                  setLatitude={(val) => setLatitude(val)}
                  setLongitude={(val) => setLongitude(val)}
                  setAddress={(val) => setAddress(val)}
                  addressEntered={addressEntered}
                  initialAddress={listing && listing.Address}
                  validated={validateError.coords ? false : true}
                  setValidated={() => setValidateError({ ...validateError, coords: false })}
                />
                <div id="w-node-_806ca343-57bd-c792-4ae3-afd7f2c3f968-be368e20" className={longitude && latitude ? "div-block-32" : "d-none"}>
                  {renderMap()}
                </div>

                <input
                  type="text"
                  className={`text-field w-node-f2016ec3-724c-ea63-a932-ba13d006002a-be368e20 w-input ${validateError.contactPhone ? "val-error" : ""}`}
                  maxLength="256"
                  name="phone"
                  data-name="phone"
                  id="phone"
                  required=""
                  placeholder={content.phone}
                  value={contactPhone}
                  onChange={(e) => {
                    setValidateError({ ...validateError, contactPhone: false });
                    setContactPhone(e.target.value);
                  }}
                />
                <input
                  type="email"
                  className={`text-field w-node-c44e90da-fb8f-b03e-1e18-127cbea5767f-be368e20 w-input ${validateError.contactEmail ? "val-error" : ""}`}
                  maxLength="256"
                  name="email-6"
                  data-name="Email 6"
                  id="email-6"
                  required=""
                  placeholder={content.email}
                  value={contactEmail}
                  onChange={(e) => {
                    setValidateError({ ...validateError, contactEmail: false });
                    setContactEmail(e.target.value);
                  }}
                />

                <textarea
                  name="description"
                  maxLength="5000"
                  id="description"
                  data-name="description"
                  className="text-field text-input w-node-_3e8829d7-dc69-7d01-fec8-1ad5a6b61cca-be368e20 w-input"
                  placeholder={content.description}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <input
                  type="number"
                  className={`text-field w-node-bb6437c1-f634-5d10-37e0-f4614208813d-be368e20 w-input ${validateError.beds ? "val-error" : ""}`}
                  maxLength="256"
                  name="beds"
                  data-name="beds"
                  id="beds"
                  required=""
                  placeholder={content.beds}
                  value={beds}
                  onChange={(e) => {
                    setValidateError({ ...validateError, beds: false });
                    setBeds(e.target.value);
                  }}
                />
                <input
                  type="number"
                  className={`text-field w-node-_205a556d-530f-af68-48ed-2eb9b658b760-be368e20 w-input ${validateError.baths ? "val-error" : ""}`}
                  maxLength="256"
                  name="baths"
                  data-name="baths"
                  id="baths"
                  required=""
                  placeholder={content.baths}
                  value={baths}
                  onChange={(e) => {
                    setValidateError({ ...validateError, baths: false });
                    setBaths(e.target.value);
                  }}
                />
                <input
                  type="number"
                  className={`text-field w-node-_3e1d7437-ceb6-b80b-57b0-25590dc52e1f-be368e20 w-input ${validateError.size ? "val-error" : ""}`}
                  maxLength="256"
                  name="size"
                  data-name="size"
                  id="size"
                  required=""
                  placeholder={content.size}
                  value={size}
                  onChange={(e) => {
                    setValidateError({ ...validateError, size: false });
                    setSize(e.target.value);
                  }}
                />

                <input
                  type="number"
                  className={`text-field w-node-_9ad88335-b3a7-fe83-bfbd-2c3dd8614242-be368e20 w-input ${validateError.price ? "val-error" : ""}`}
                  maxLength="256"
                  name="price"
                  data-name="price"
                  id="price"
                  placeholder={content.price}
                  value={price}
                  onChange={(e) => {
                    setValidateError({ ...validateError, price: false });
                    setPrice(e.target.value);
                  }}
                />
                <input
                  type="number"
                  className={`text-field w-node-_72ebd21f-8057-17ec-6020-f52a8fc7e528-be368e20 w-input ${validateError.leaseLength ? "val-error" : ""}`}
                  maxLength="256"
                  name="lease-length"
                  data-name="lease length"
                  id="lease-length"
                  required=""
                  placeholder={content.leaseLength}
                  value={leaseLength}
                  onChange={(e) => {
                    setValidateError({ ...validateError, leaseLength: false });
                    setLeaseLength(e.target.value);
                  }}
                />
                <div id="w-node-_4b2bfbed-d7dd-1b03-f096-ce146567841f-be368e20" className="div-block-34">
                  <DatePicker
                    name="date-added"
                    data-name="date added"
                    id="date-added"
                    className={`${!listing ? "text-field w-input" : "d-none"} ${validateError.dateListed ? "val-error" : ""}`}
                    selected={dateListed}
                    onChange={(date) => {
                      setValidateError({ ...validateError, dateListed: false });
                      setDateListed(date);
                    }}
                  />
                </div>
                {/* <input
                  type="number"
                  className="text-field w-node-f71c539d-ac4b-9562-f906-e6ae53653899-be368e20 w-input"
                  maxLength="256"
                  placeholder={content.latitude}
                  id="email-3"
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                />
                <input
                  type="number"
                  className="text-field w-node-df8048d8-63e8-dd28-393c-c2d4e17f6acc-be368e20 w-input"
                  maxLength="256"
                  placeholder={content.longitude}
                  id="email-2"
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                /> */}
              </div>
              <div className="form-sub-title" onClick={() => changeExpanded(1)}>
                <h2 className="form-heading">{content.headings[1]}</h2>
                <Hamburger clicked={expanded[1]} onHamClick={() => changeExpanded(1)} />
              </div>

              <div className={`w-layout-grid grid-14 ${expanded[1] ? "form-block-show" : "form-block-hide"}`}>
                <div id="w-node-e02a8b3d-e984-8936-e13e-6badb4c9977f-be368e20">
                  <Dropdown content={content.parkingType} value={parkingType} setValue={setParkingType} />
                </div>

                <div id="w-node-cea29643-f9da-729d-5747-e977ebd1d05b-be368e20">
                  <Dropdown content={content.propertyType} value={propertyType} setValue={setPropertyType} />
                </div>

                <input
                  type="text"
                  className="text-field w-node-_5ed44f57-ac3f-d347-3647-de895c2fb55e-be368e20 w-input"
                  maxLength="256"
                  name="parking-spots"
                  data-name="parking spots"
                  id="parking-spots"
                  placeholder={content.parkingSpots}
                  value={parkingSpots}
                  onChange={(e) => setParkingSpots(e.target.value)}
                  disabled={parkingType === "none" ? true : false}
                />

                <div id="w-node-c0b282df-fb5b-0f29-e71b-4c096d142682-be368e20">
                  <JsonInput
                    name={content.outdoorSpace}
                    value={outdoorSpace}
                    onChange={(values) => {
                      setOutdoorSpace(values);
                    }}
                    defaultNum={(outdoorSpace && outdoorSpace.length) || 1}
                    secondaryFormat={true}
                  />
                </div>

                <div id="w-node-_37b73e85-e110-86f6-f77f-4121e45a29e6-be368e20">
                  <CheckSliderMiddle label={content.pets} value={pets} valueChange={(value) => setPets(value)} />
                </div>
                <div id="w-node-_1f9f96c6-b7c0-d49e-13f9-14365f464963-be368e20">
                  <CheckSliderMiddle label={content.smoking} value={smoking} valueChange={(value) => setSmoking(value)} />
                </div>
                <div id="w-node-_44218a53-5606-e830-1d63-a97433823e60-be368e20">
                  <CheckSliderMiddle label={content.furnished} value={furnished} valueChange={(value) => setFurnished(value)} />
                </div>

                <div id="w-node-_3014b9f0-fbdd-f737-330d-dcdd771fd10b-be368e20" className="div-block-36">
                  <div className="listing-text checklist">{content.utilities}</div>
                  <div>
                    <CheckSlider value={heatIncluded} name={content.heatIncluded} onChange={setHeatIncluded} />
                    <CheckSlider value={internetIncluded} name={content.internetIncluded} onChange={setInternetIncluded} />
                    <CheckSlider value={electricityHydroIncluded} name={content.electricityHydroIncluded} onChange={setElectricityHydroIncluded} />
                    <CheckSlider value={satelliteIncluded} name={content.satelliteIncluded} onChange={setSatelliteIncluded} />
                    <CheckSlider value={cableIncluded} name={content.cableIncluded} onChange={setCableIncluded} />
                    <CheckSlider value={waterIncluded} name={content.waterIncluded} onChange={setWaterIncluded} />
                  </div>
                </div>

                <div id="w-node-_53007b88-a6a8-4746-5f07-1093b2d23a31-be368e20" className="div-block-36">
                  <div className="listing-text checklist">{content.appliances}</div>
                  <div>
                    <CheckSlider value={airConditioning} name={content.airConditioning} onChange={setAirConditioning} />
                    <CheckSlider value={dishwasher} name={content.dishwasher} onChange={setDishwasher} />
                    <CheckSlider value={laundryEnsuite} name={content.laundryEnsuite} onChange={setLaundryEnsuite} />
                    <CheckSlider value={laundryFacilities} name={content.laundryFacilities} onChange={setLaundryFacilities} />
                  </div>
                  <JsonInput
                    value={appliancesOther}
                    onChange={(values) => {
                      setAppliancesOther(values);
                    }}
                    defaultNum={(appliancesOther && appliancesOther.length) || 0}
                  />
                </div>

                <div id="w-node-ee61ef04-73ae-e7b8-85f4-d024d6af160e-be368e20" className="div-block-36">
                  <div className="listing-text checklist">{content.amenities}</div>
                  <div>
                    <CheckSlider value={gym} name={content.gym} onChange={setGym} />
                    <CheckSlider value={wheelchairAccess} name={content.wheelchairAccess} onChange={setWheelchairAccess} />
                    <CheckSlider value={balcony} name={content.balcony} onChange={setBalcony} />
                    <CheckSlider value={fireplace} name={content.fireplace} onChange={setFireplace} />
                    <CheckSlider value={pool} name={content.pool} onChange={setPool} />
                  </div>
                  <JsonInput
                    value={amenitiesOther}
                    onChange={(values) => {
                      setAmenitiesOther(values);
                    }}
                    defaultNum={(amenitiesOther && amenitiesOther.length) || 0}
                  />
                </div>
              </div>

              <div className={listing && listing.Photos ? "form-sub-title" : "d-none"} onClick={() => changeExpanded(2)}>
                <h2 className="form-heading ">{content.headings[2]}</h2>
                <Hamburger clicked={expanded[2]} onHamClick={() => changeExpanded(2)} />
              </div>
              <div className={` ${expanded[2] ? "form-block-show" : "form-block-hide"} ${listing && listing.Photos ? "" : "d-none"} `}>
                <PhotoCurrentDisplay photos={listing && listing.Photos} />
              </div>

              <div className="form-sub-title" onClick={() => changeExpanded(3)}>
                <h2 className="form-heading">{content.headings[3]}</h2>
                <Hamburger clicked={expanded[3]} onHamClick={() => changeExpanded(3)} />
              </div>
              <div className={`w-layout-grid grid-16 ${expanded[3] ? "form-block-show" : "form-block-hide"} `}>
                <div id="w-node-_8df87a18-c5b7-a2c8-0a9c-662be69c294f-be368e20">
                  <label className="custom-file-upload button3 ">
                    <input
                      type="file"
                      name="Photos"
                      multiple
                      onChange={(e) => {
                        setPhotos(e.target.files);
                        var tmp = [];
                        for (let i = 0; i < e.target.files.length; i++) {
                          tmp.push(i);
                        }
                        setUploadFilter(tmp);
                      }}
                      accept="image/gif, image/jpeg, image/png"
                    />
                    {content.photoChoose}
                  </label>
                </div>
                <PhotoUploadDisplay photos={photos} removeImages={removeImages} uploadFilter={uploadFilter} />
              </div>
              <input type="submit" value={content.submitBtn} data-wait="Please wait..." className="button3 listing-button" onClick={onFormSubmit} />
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default ListingForm;
