import React, { useState } from "react";

const CheckSliderMiddle = ({ label, value, valueChange }) => {
  const [selected, setSelected] = useState(value);

  const handleChange = (newValue) => {
    valueChange(newValue);
    setSelected(newValue);
  };

  return (
    <div className="div-block-9 app CheckSliderMiddle-cont">
      <div className="listing-text">{label}</div>
      <div className="CheckSliderMiddle">
        <div onClick={() => handleChange(false)} className={`zoom left ${selected === false ? "selected" : ""}`}>
          <i className="fas fa-times"></i>
        </div>
        <div onClick={() => handleChange(null)} className={`zoom ${selected === null ? "selected" : ""}`}>
          <i className="fas fa-circle"></i>
        </div>
        <div onClick={() => handleChange(true)} className={`zoom right ${selected === true ? "selected" : ""}`}>
          <i className="fas fa-check"></i>
        </div>
      </div>
    </div>
  );
};

export default CheckSliderMiddle;
